import React, { useState, useContext, useMemo } from 'react'
import * as Page from '../../components/Page'
import {
  ICreatePageProps,
  IForm,
  TCreateValidationSchemaOptions,
} from './types'
import { Translater } from '../../config'
import * as Contexts from '../../contexts'
import * as Buttons from '../../components/Buttons'
import { useValidation } from '../../hooks'
import * as Components from './components'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { config } from '../../config'
import { useHttp } from '../../hooks'
import { useHistory } from 'react-router-dom'

const CreatePage: React.FC<ICreatePageProps> = () => {
  const { language } = useContext(Contexts.LanguageContext)
  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)
  const { token } = useContext(Contexts.AuthContext)
  const { request } = useHttp()
  const [rest, setRest] = useState<any>({})
  const [form, setForm] = useState<IForm>({
    name: '',
    price: '0',
    restaurant: [''],
    photo: null,
    weight: 0,
  })
  const history = useHistory()

  const schema = useMemo<TCreateValidationSchemaOptions>(
    () => ({
      name: {
        condition: form.name.length > 2,
        error: `${Translater.OptionsForProductsPage.error.length[language.slug]}
                    ${Translater.OptionsForProductsPage.error.name[
          language.slug
          ]
          }`,
      },
      price: {
        condition: Number(form.price) > 0 && form.price != '0',
        error: `${Translater.OptionsForProductsPage.error.length[language.slug]}
                    ${Translater.OptionsForProductsPage.error.price[
          language.slug
          ]
          }`,
      },
      photo: {
        condition: form.photo != null,
        error: `${Translater.OptionsForProductsPage.error.photo[language.slug]
          }`,
      },
      weight: {
        condition: form.weight.toString().length > 0,
        error: `${Translater.OptionsForProductsPage.error.length[language.slug]}
                     ${Translater.Placeholders.g[language.slug]}
                    `,
      },
    }),
    [form]
  )

  const { validation, errors } = useValidation(schema)

  const Events = {
    CreateHandler: async () => {
      try {
        await validation()

        const data = new FormData()
        console.log("FORM", form)

        if (Array.isArray(form.restaurant)) {
          form.restaurant.map((rest) => {
            return data.append('restaurant[]', rest)
          })
        }
        else {
          data.append('restaurant[]', form.restaurant);
        }

        data.append('name', form.name)
        data.append('price', form.price)
        // @ts-ignore
        data.append('photo', form.photo)
        data.append('weight', form.weight.toString())
        await request(`${config.API}/addition`, 'POST', data, {
          Authorization: token as string,
        })

        history.goBack()
        setForm({
          weight: 0,
          name: '',
          price: '0',
          photo: null,
          restaurant: [''],
        })


      } catch (e) {
        console.log("ERROR", e)
        toogleIsAlertOpen(true)
      }
    },
  }

  return (
    <Page.Wrapper
      title={Translater.OptionsForProductsPage.CreatePage[language.slug]}
    >
      <Page.Header
        backButtonTitle={
          Translater.OptionsForProductsPage.CreatePage[language.slug]
        }
        backButtonLink='/options-for-products'

      />
      <Buttons.Container
        disabled={false}
        createHandler={Events.CreateHandler}
      />

      <Components.ConfigBlock
        form={form}
        setForm={setForm}
        rest={rest}
        setRest={setRest}
      />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity='error'>
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default CreatePage
