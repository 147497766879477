import React, {
    Fragment,
    useState,
    useEffect,
    useContext,
} from 'react'

import * as Contexts from '../../../../contexts'
import * as Table from '../../../../components/Table'

import { useHistory } from 'react-router-dom'
import { config, Translater } from '../../../../config'
import { useHttp } from '../../../../hooks/http.hook'
import { ICategory } from '../../../../types/items'
import { ITrackProps } from './types'


const Track: React.FC<ITrackProps> = ({
 data,
 nesting,
 reloadHandler
}) => {

    const history = useHistory()

    const { token } = useContext(Contexts.AuthContext)
    const { access } = useContext(Contexts.UserContext)
    const { language } = useContext(Contexts.LanguageContext)

    const { loading, request } = useHttp()

    const Events = {
        deleteHandler: () => {
            const answer = window.confirm(Translater.Alert.delete[language.slug])
            if (answer) Callbacks.Delete()
        },
        editHandler: () => {
            history.push(`/referals/${data._id}`)
        }
    }

    const Callbacks = {
        Delete: async () => {
            try {
                await request(`${config.API}/referals/${data._id}`, 'DELETE', null, {
                    Authorization: (token as string),
                })

                reloadHandler()
            } catch (e) {
                console.log(e)
            }
        },
    }

    return (
        <Fragment>
            <Table.Track
                type='referals'
            >

                <Table.Cell
                    text={data.refCode}
                />

                <Table.Cell
                    text={data.sum}
                />

                <Table.Cell
                    date={data.updated}
                />

                <Table.Cell
                    date={data.created}
                /> 

                <Table.Cell 
                    text={data.user ? data.user.name : Translater.TableTitles.noInfo[language.slug]}
                />

                <Table.Cell
                    text={data.isUsed ? Translater.Select.yes[language.slug] : Translater.Select.no[language.slug]}
                />

                <Table.Cell
                    type='control'
                    editButton
                    deleteButton={true}
                    editHandler={Events.editHandler}
                    deleteHandler={Events.deleteHandler}
                />
            </Table.Track>

        </Fragment>
    )
}

export default Track
