import React, { useContext, useState } from 'react'

import * as Contexts from '../../contexts'

import { Translater } from '../../config'
import { IItemProps } from './types'
import { Alert } from '@material-ui/lab'
import { Snackbar } from '@material-ui/core'

const Item: React.FC<IItemProps> = ({ data }) => {
  const { language } = useContext(Contexts.LanguageContext)

  const [isVisible, toggleVisible] = useState<boolean>(true)

  const clickHandler = () => {
    toggleVisible(!isVisible)
  }

  if (!isVisible) return null

  return (
    <Snackbar
      open={isVisible}
      autoHideDuration={10000}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={() => toggleVisible(false)}
    >
      <Alert
        severity='success'
        onClick={clickHandler}
        style={{ marginTop: 10 }}
      >
        {data.action === 'BIRTHDAY'
          ? `${Translater.Birthday[language.slug]}${data.user?.name}${new Date(
              data.user?.dateOfBirthday as string
            ).toLocaleDateString()} ${Translater.Phone[language.slug]}${
              data.user?.phone
            }
          `
          : null}
        {data.slug === 'new_order'
          ? Translater.NotificationsPage.new_message[language.slug]
          : Translater.NotificationsPage.save_message[language.slug]}
      </Alert>
    </Snackbar>
  )
}

export default Item
