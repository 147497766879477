import React, { Fragment, useState, useContext, useEffect } from 'react'

import * as Contexts from '../../../contexts'
import * as Buttons from '../../../components/Buttons'
import * as Form from '../../../components/Form'

import { config, Translater } from '../../../config'
import { useHttp } from '../../../hooks/http.hook'

import { SHiddenOptions } from '../../../components/Form/static'
import { IStock, IOption } from '../../../types/items'
import { IConfigBlockProps } from './types'
import ListProducts from '../ListProducts'
import ListCategories from '../ListCategories'
import ListRestaurants from '../ListRestaurants'
import { IRestaurant } from '../../../types/items'
import { Editor } from '../../../components/Editor/Editor'
import moment from 'moment'

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  data,
  form,
  isValid,
  multiLangValues,
  setParentID,
  inputHandler,
  selectHandler,
  //@ts-ignore
  HandlerShowProduct,
  editorHandler,
  setForm
}) => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { request } = useHttp()

  const [inputValue, setInputValue] = useState<string>('')
  const [categoryOptions, setCategoryOptions] = useState<any[]>([])
  const [categorySelectValue, setCategorySelectValue] = useState<any>({
    label: '',
    value: '',
  })
  const [restaurantSelectValue, setRestaurantSelectValue] = useState<any>({
    label: '',
    value: '',
  })
  const [restaurantOptions, setRestaurantOptions] = useState<IRestaurant[]>([])

  const Events = {
    inputHandler: (value: string) => {
      console.log(value);
      setInputValue(value)
    },
    loadOptions: async (inputValue: string) => {
      try {
        const response: IStock[] = await request(
          `${config.API}/stocks/all`,
          'GET',
          null,
          {
            Authorization: token as string,
          }
        )
        const formatted: IOption[] = []
        if (response.length) {
          for (const item of response) {
            const option = {
              value: item._id as string,
              label: item.name as string,
            }
            formatted.push(option)
          }
        }
        setCategoryOptions(formatted)
        return formatted
      } catch (e) {
        console.log(e)
      }
    },
    optionHandler: (option: any) => {
      setCategorySelectValue(option)
      setParentID(option.value)
    },
    loadRestaurantOptions: async () => {
      try {
        const response: IRestaurant[] = await request(
          `${config.API}/restaurants`,
          'GET',
          null,
          {
            Authorization: token as string,
          }
        )

        response && setForm({ ...form, restaurants: response.map(element => element._id) })
        setRestaurantOptions(response)
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Events.loadRestaurantOptions();
  }, []);

  return (
    <Fragment>

      {console.log("work")}
      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.title[language.slug]}
        text={access.stocks?.rule !== 'change' ? form.title : ''}
      >


        {access.stocks?.rule === 'change' ? (
          multiLang ? (
            <Form.MultiLang
              placeholder={Translater.Placeholders.minTwo[language.slug]}
              minLength={2}
              multiLangTitles={multiLangValues}
              inputHandler={inputHandler}
            />
          ) : (
            <Form.Input
              name='title'
              type='text'
              placeholder={Translater.Placeholders.minTwo[language.slug]}
              isValid={isValid.title}
              value={form.title}
              inputHandler={inputHandler}
            />
          )
        ) : null}


      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.photo[language.slug]}
      >
        <Form.Input
          type='file'
          name='photo'
          isValid={isValid.photo}
          inputHandler={inputHandler}
        />
      </Form.LabelField>
      {data?.photo && (typeof data?.photo === 'string' || data?.photo instanceof String) && (<img
        alt='Category'
        src={`${config.serverURL}${data?.photo}`}
        style={{ objectFit: 'cover', width: 350, height: 250 }}
      />)}
      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.publicationDate[language.slug]}
      >
        <p className='label-text'>
          {data?.updated ? moment(data?.updated).format("DD.MM.YYYY") : moment(data?.created).format("DD.MM.YYYY")}
        </p>
      </Form.LabelField>

      {/* {restaurantOptions ? (
        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.restaurants[language.slug]}
          text={''}
        >
          <Form.Dropdown
            disabledValue={Translater.Placeholders.chooseRestaurant2[language.slug]}
            items={restaurantOptions}
            item={form.restaurants[0]}
            name='restaurants'
            changeHandler={selectHandler}
          />
        </Form.LabelField>
      ) : null} */}

      <Editor
        initialValue={form.body}
        onEditorChange={editorHandler}
      />

    </Fragment>
  )
}

export default ConfigBlock
