import { ITableTitle } from './../../../../types/table'

export const SHomeTitles: ITableTitle[] = [
  { type: 'header', slug: 'increment' },
  { type: 'header', slug: 'guests' },
  { type: 'header', slug: 'phone' },
  { type: 'header', slug: 'date' },
  { type: 'header', slug: 'created' },
  { type: 'header', slug: 'restaurant' },
  { type: 'header', slug: '' },
]
