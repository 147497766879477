import React, { Fragment } from 'react'
import { Translater } from '../../config'

import * as Buttons from '../Buttons'
import * as Form from '../Form'

import { IFilterProps } from './types'


const Filter: React.FC<IFilterProps> = ({
 children,
 form,
 button,
 agePicker,
 events = {},
 label = "",
}) => { 

    return (
        <div className='filter-bar'>
            <div className='filter-bar-data'>
                {children}
            </div>

            <div className='filter-bar-inputs'>

                {events && agePicker ? (
                    <div className="dates-flex">
                        <Form.Input
                            type="date"
                            name='dateFrom'
                            value={form?.dateFrom}
                            label={label}
                            inputHandler={(events.inputHandler as any)}
                            addClassName='date-input'
                        />

                        <Form.Input
                            type="date"
                            name='dateTo'
                            value={form?.dateTo}
                            inputHandler={(events.inputHandler as any)}
                        />
                    </div>
                ) : null}

            </div>
        </div>
    )
}



export default Filter
