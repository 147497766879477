import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'
import * as Buttons from '../../components/Buttons'

import { useHttp } from '../../hooks'
import { IStock } from '../../types/items'
import { config, Translater } from '../../config'
import { useParams, useHistory } from 'react-router-dom'
import {
  TMutliLangTitles,
  TPossibleMultiLangTitle,
} from '../../types/multiLang'
import { useValidation } from '../../hooks'
import { TDetailValidationSchema } from './types'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

const DetailPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { id } = useParams() as any
  const history = useHistory()
  const { loading, request } = useHttp()

  const [stock, setStock] = useState<IStock | null>(null)
  const [isValid, toggleValid] = useState({
    name: false,
    photo: false,
    productsList: true,
    dateStart: false,
    dateEnd: false,
  })

  const [form, setForm] = useState<IStock>({
    name: '',
    photo: '',
    percent: 0,
    description: '',
    status: false,
    dateStart: 0,
    dateEnd: 0,
    timeEnd: 0,
    productsList: '',
    categoriesList: '',

    restaurantsList: '',
  })

  const [multiLangValues, setMultiLangValues] = useState<TMutliLangTitles>({
    'title[EN]': '',
    'title[RU]': '',
    'title[UA]': '',
  })

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const schema = useMemo<TDetailValidationSchema>(
    () => ({
      name: {
        condition: form.name.length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.name[language.slug]}`,
      },

      dateStart: {
        condition: !!form.dateStart,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.dateStart[language.slug]}`,
      },
      dateEnd: {
        condition: !!form.dateEnd,
        error: `
        ${Translater.ErrorField[language.slug]}:
        ${Translater.TableTitles.dateEnd[language.slug]}`,
      },
      restaurantsList: {
        condition: (true),
        error: `
        ${Translater.ErrorField[language.slug]}:
        ${Translater.TableTitles.restaurantsList[language.slug]}`,
      },
      categoriesList: {
        condition: !!form.categoriesList,
        error: `
        ${Translater.ErrorField[language.slug]}:
        ${Translater.TableTitles.categoriesList[language.slug]}`,
      },
    }),
    [form, Translater, language]
  )
  const { errors, validationSchema, validation } = useValidation(schema)

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      //@ts-ignore
      if (e.target.type === 'file')
        //@ts-ignore
        setForm({ ...form, photo: e.target.files[0] })
      else if (e.target.name.split('[')[1]) {
        setMultiLangValues({
          ...multiLangValues,
          [e.target.name]: e.target.value,
        })
      } else setForm({ ...form, [e.target.name]: e.target.value })
    },

    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    saveHandler: async () => {
      try {
        await validation()

        Callbacks.Save()
      } catch (e) {
        toogleIsAlertOpen(true)
        console.log('createHandler => e', e)
      }
      //
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IStock = await request(
          `${config.API}/stocks/${id}`,
          'GET',
          null,
          {
            Authorization: token as string,
          }
        )

        if (response) {
          setStock(response)
        }
      } catch (e) {
        console.log(e)
      }
    },
    Save: async () => {
      try {
        const data = new FormData()

        if (multiLang) {
          for (let key in multiLangValues) {
            data.append(key, multiLangValues[key as TPossibleMultiLangTitle])
          }
        } else data.append('name', form.name)

        data.append('photo', form.photo)
        // data.append('name', form.name)
        data.append('description', form.description)
        data.append('percent', form.percent as string)
        data.append('dateStart', form.dateStart as string)
        data.append('dateEnd', form.dateEnd as string)
        data.append('productsList', (form.productsList as string) || '')
        data.append('categoriesList', form.categoriesList as any)
        data.append('restaurantsList', form.restaurantsList as any)


        const response = await request(
          `${config.API}/stocks/update/${id}`,
          //@ts-ignore
          'PUT',
          data,
          {
            Authorization: token as string,
          }
        )

        if (response) history.push('/stocks')
      } catch (e) {
        console.log(e)
      }
    },

    Delete: async () => {
      try {
        await request(`${config.API}/stocks/${id}`, 'DELETE', null, {
          Authorization: token as string,
        })

        history.push('/stocks')
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [id])

  useEffect(() => {
    if (stock) {
      setForm({
        ...form,
        name: stock.name,
        photo: stock.photo,
        percent: stock.percent,
        description: stock.description,

        dateStart: `${new Date(stock.dateStart).getFullYear().toString()}-${new Date(stock.dateStart).getMonth().toString().length === 2
          ? new Date(stock.dateStart).getMonth() + 1
          : '0' + (+new Date(stock.dateStart).getMonth() + 1)
          }-${new Date(stock.dateStart).getDate().toString().length === 2
            ? new Date(stock.dateStart).getDate()
            : '0' + new Date(stock.dateStart).getDate()
          }`,

        dateEnd: `${new Date(stock.dateEnd).getFullYear().toString()}-${new Date(stock.dateEnd).getMonth().toString().length === 2
          ? new Date(stock.dateEnd).getMonth() + 1
          : '0' + (+new Date(stock.dateEnd).getMonth() + 1)
          }-${new Date(stock.dateEnd).getDate().toString().length === 2
            ? new Date(stock.dateEnd).getDate()
            : '0' + new Date(stock.dateEnd).getDate()
          }`,
        productsList: stock.productsList,
        categoriesList: stock.categoriesList,
        restaurantsList: stock.restaurantsList,
      })
    }

  }, [stock])

  useEffect(() => {
    if (multiLang) {
      let isRight = true

      for (let key in multiLangValues) {
        if (
          multiLangValues[key as TPossibleMultiLangTitle].length >= 2 &&
          isRight
        ) {
          toggleValid({ ...isValid, name: true })
          isRight = true
        } else {
          isRight = false
          toggleValid({ ...isValid, name: false })
        }
      }
    } else {
      if (form.name.length >= 2) toggleValid({ ...isValid, name: true })
      else toggleValid({ ...isValid, name: false })
    }
  }, [form.name, multiLangValues])





  if (loading || !stock) return <Page.Preloader />

  return (
    <Page.Wrapper title={stock.name}>
      <Page.Header
        backButtonTitle={Translater.StocksDetailPage.title[language.slug]}
        backButtonLink="/stocks"

      />
      <Buttons.Container
        disabled={loading}
        saveHandler={
          access.stocks?.rule === 'change' ? Events.saveHandler : false
        }
        deleteHandler={
          access.stocks?.rule === 'change' ? Events.deleteHandler : false
        }
      />

      <Components.ConfigBlock
        data={stock}
        form={form}
        from={'edit'}
        //@ts-ignore
        isValid={validationSchema}
        multiLangValues={multiLangValues}
        inputHandler={Events.inputHandler}
        selectHandler={Events.selectHandler}
        setForm={setForm}
      />
      <Components.DetailBlock data={stock} />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity='error'>
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default DetailPage
