import { TLanguages } from '../types/contexts/language'

export const Languages: TLanguages = {
  ua: {
    slug: 'ua',
    label: 'Українська',
  },
  ru: {
    slug: 'ru',
    label: 'Русский',
  },
}

export const Translater = {
  Birthday: {
    ua: 'День народження у ',
    ru: 'День рождения у ',
  },
  Phone: {
    ua: 'Телефон',
    ru: 'Телефон',
  },
  Success: {
    save: {
      ua: 'Ви успiшно змiнили порядок ресторанiв',
      ru: 'Вы успешно изменили порядок ресторанов',
    },
  },
  Erros: {
    oops: {
      ua: 'Упс...',
      ru: 'Упс...',
    },
    network: {
      ua: 'Відсутнє підключення до інтернету',
      ru: 'Отсутствует подключение к интернету',
    },
    ws: {
      ua: 'Відсутнє підключення до WebSocket.\nПерезавантажте сторiнку',
      ru: 'Отсутствует подключение к WebSocket\nПерезагрузите страницу',
    },
    forbidden: {
      ua: 'Ви не немаєте прав доступу!',
      ru: 'Нет прав доступа!',
    },
  },
  WS: {
    Actions: {
      STATUS_CHANGE: {
        ua: '',
        ru: '',
      },
    },
    Types: {
      ORDER: {
        ua: '',
        ru: '',
      },
    },
  },
  Sidebar: {
    options: {
      ua: 'Опції для продуктів',
      ru: 'Опции для продуктов',
    },
    online: {
      ua: 'Онлайн',
      ru: 'Онлайн',
    },
    home: {
      ua: 'Головна',
      ru: 'Главная',
    },
    feedback: {
      ua: 'Вiдгуки',
      ru: 'Отзывы',
    },
    orders: {
      ua: 'Замовлення',
      ru: 'Заказы',
    },
    categories: {
      ua: 'Категорії',
      ru: 'Категории',
    },
    restaurants: {
      ua: 'Ресторани',
      ru: 'Рестораны',
    },
    booking: {
      ua: 'Заявки на бронювання',
      ru: 'Заявки на бронь',
    },
    bookingDetails: {
      ua: 'Бронювання',
      ru: 'Бронирование',
    },
    referals: {
      ua: 'Реферальні коди',
      ru: 'Реферальные коды',
    },
    products: {
      ua: 'Продукти',
      ru: 'Продукты',
    },
    sales: {
      ua: 'Акцiї',
      ru: 'Акции',
    },
    users: {
      ua: 'Користувачi',
      ru: 'Пользователи',
    },
    birthdays: {
      ua: 'Дні народження',
      ru: 'Дни рождения',
    },
    staff: {
      ua: 'Персонал',
      ru: 'Персонал',
    },
    roles: {
      ua: 'Посади',
      ru: 'Должности',
    },
    history: {
      ua: 'Архів',
      ru: 'Архив',
    },
    notifications: {
      ua: 'Повідомлення',
      ru: 'Уведомления',
    },
    delivery: {
      ua: 'Доставка та оплата',
      ru: 'Доставка и оплата',
    },
    stocks: {
      ua: 'Акції',
      ru: 'Акции',
    },
    settings: {
      ua: 'Налаштування',
      ru: 'Настройки',
    },
    news: {
      ua: 'Новини',
      ru: 'Новости',
    },
  },
  Buttons: {
    controlPoints: {
      ua: 'Доставка в iншi райони',
      ru: 'Доставка в другие районы',
    },
    addVariations: {
      ua: 'Додати варіації',
      ru: 'Добавить вариации',
    },
    login: {
      ua: 'Увійти',
      ru: 'Войти',
    },
    goBack: {
      ua: 'Повернутись назад',
      ru: 'Вернуться назад',
    },
    reload: {
      ua: 'Перезапустити',
      ru: 'Перезапустить',
    },
    logout: {
      ua: 'Вийти',
      ru: 'Выйти',
    },
    save: {
      ua: 'Зберегти',
      ru: 'Сохранить',
    },
    delete: {
      ua: 'Видалити',
      ru: 'Удалить',
    },
    create: {
      ua: 'Створити',
      ru: 'Создать',
    },
    addOptions: {
      ua: 'Додати опції',
      ru: 'Добавить опции',
    },
    deleteOptions: {
      ua: 'Видалити опції',
      ru: 'Удалить опции',
    },
    add: {
      ua: 'Додати',
      ru: 'Добавить',
    },
    send: {
      ua: 'Надіслати',
      ru: 'Отправить',
    },
  },
  Alert: {
    save: {
      ua: 'Зберегти?',
      ru: 'Сохранить?',
    },
    delete: {
      ua: 'Видалити?',
      ru: 'Удалить?',
    },
  },
  Select: {
    restaurant: {
      ua: 'По ресторану',
      ru: 'По ресторану',
    },
    status: {
      ua: 'По статусу',
      ru: 'По статусу',
    },
    id: {
      ua: 'По ID',
      ru: 'По ID',
    },
    byCreateDate: {
      ua: 'По датi створення',
      ru: 'По дате создания',
    },
    new: {
      ua: 'Новий',
      ru: 'Новый',
    },
    working: {
      ua: 'Готується',
      ru: 'Готовится',
    },
    delivering: {
      ua: 'Доставляється',
      ru: 'Доставляется',
    },
    rejected: {
      ua: 'Відмовлений',
      ru: 'Отменён',
    },
    done: {
      ua: 'Завершений',
      ru: 'Завершенный',
    },
    yes: {
      ua: 'Так',
      ru: 'Да',
    },
    no: {
      ua: 'Ні',
      ru: 'Нет',
    },
    view: {
      ua: 'Перегляд',
      ru: 'Просмотр',
    },
    change: {
      ua: 'Редагування',
      ru: 'Редактирование',
    },
    hide: {
      ua: 'Приховати',
      ru: 'Скрыть',
    },
    processing: {
      ua: 'Обробка',
      ru: 'Обработка',
    },
    'payment-error': {
      ua: 'Помилка при оплаті',
      ru: 'Ошибка при оплате',
    },
    'payment-paid': {
      ua: 'Сплачено',
      ru: 'Оплачено',
    },
  },
  Placeholders: {
    chooseCategory:
    {
      ua: 'Виберіть категорію',
      ru: 'Выберите категорию'
    },
    chooseRestaurant:
    {
      ua: 'Виберіть ресторан',
      ru: 'Выберите ресторан'
    },
    chooseRestaurant2:
    {
      ua: 'Вибір ресторану',
      ru: 'Выбор ресторана'
    },
    chooseProduct:
    {
      ua: 'Виберіть продукт',
      ru: 'Выберите продукт'
    },
    startRange: {
      ua: 'Початок кiлометражу',
      ru: 'Начало километража',
    },
    endRange: {
      ua: 'Кiнець кiлометражу',
      ru: 'Конец километража',
    },
    searchAddition: {
      ua: 'Пошук по добавкам',
      ru: 'Поиск по добавкам',
    },
    parentProduct: {
      ua: 'Пошук по бат. продуктам',
      ru: 'Поиск по род. продуктам',
    },
    category: {
      ua: 'Пошук по категоріям',
      ru: 'Поиск по категориям',
    },
    filterByCategory:
    {
      ua: 'Фільтр по категоріям',
      ru: 'Фильтр по категориям'
    },
    searchByRests: {
      ua: 'Пошук по ресторанам',
      ru: 'Поиск по ресторанам',
    },
    g: {
      ua: 'Грамми',
      ru: 'Граммы',
    },
    options: {
      title: {
        ua: 'Назва опції',
        ru: 'Имя опции',
      },
      price: {
        ua: 'Ціна опції',
        ru: 'Цена опции',
      },
      photo: {
        ua: 'Фото',
        ru: 'Фото',
      },
    },
    colorPicker: {
      ua: 'Колiр',
      ru: 'Цвет',
    },
    minTwo: {
      ua: 'Не менш 2 букв',
      ru: 'Не менее 2 букв',
    },
    minThree: {
      ua: 'Не менш 3 букв',
      ru: 'Не менее 3 букв',
    },
    minFour: {
      ua: 'Не менш 4 букв',
      ru: 'Не менее 4 букв',
    },
    minSix: {
      ua: 'Не менш 6 букв',
      ru: 'Не менее 6 букв',
    },
    noOptionsMessage: {
      ua: 'Нема збігів :(',
      ru: 'Нету совпадений :(',
    },
    loadingMessage: {
      ua: 'Завантаження...',
      ru: 'Загрузка...',
    },
    searchInput: {
      ua: 'Пошук за випадковим збігом',
      ru: 'Поиск по совпадению',
    },
    search: {
      ua: 'Пошук по замовленням',
      ru: 'Поиск по заказам',
    },
    tapsLat: {
      ua: 'Введiть широту',
      ru: 'Введите широту',
    },
    tapsLng: {
      ua: 'Введiть довготу',
      ru: 'Введите долготу',
    },
    tapsLinks: {
      ua: 'Введiть посилання',
      ru: 'Введите ссылку',
    },
  },
  TableTitles: {
    archive: {
      ua: '',
      ru: '',
    },
    position: {
      ua: '№',
      ru: '№',
    },
    hasVariations:
    {
      ua: 'Є варіації',
      ru: 'Есть вариации'
    },
    addition:
    {
      ua: 'Додатки',
      ru: 'Добавки',
    },
    used: {
      ua: 'Викоростовано',
      ru: 'Выполненно',
    },
    operation: {
      ua: 'Операцiя',
      ru: 'Операция',
    },
    g: {
      ua: 'Вага',
      ru: 'Вес',
    },
    news: {
      ua: 'Новини',
      ru: 'Новости',
    },
    bonusesToOrders: {
      ua: 'Відсоток оплати бонусами при замовленні',
      ru: 'Процент оплаты бонусами при заказе',
    },
    usersSearch: {
      ua: 'Пошук по користувачам',
      ru: 'Поиск по пользователям',
    },

    noInfo: {
      ua: 'Немає інформації',
      ru: 'Нету информации',
    },
    priceToDoor: {
      ua: 'Оплата доставки',
      ru: 'Оплата доставки',
    },
    freeDeliverySum: {
      ua: 'Сумма до якої безкоштовна доставка',
      ru: 'Сумма до которой бесплатная доставка',
    },
    priceToHome: {
      ua: 'Ціна до дому',
      ru: 'Цена к дому',
    },
    default: {
      ua: 'За замовчуванням',
      ru: 'По умолчанию',
      true: {
        ua: 'Так',
        ru: 'Да',
      },
      false: {
        ua: 'Ні',
        ru: 'Нет',
      },
    },
    field: {
      ua: "Ім'я",
      ru: 'Имя',
    },
    incRest: {
      ua: 'По ID замовлення',
      ru: 'По ID заказа',
    },
    id: {
      ua: 'По ID',
      ru: 'По ID',
    },
    byCreateDate: {
      ua: 'По датi створення',
      ru: 'По дате создания',
    },
    lastUpdateDate: {
      ua: 'Дата змiни',
      ru: 'Дата изменения',
    },
    publicationDate: {
      ua: 'Дата публікації/Змiни',
      ru: 'Дата публикации/Изменения',
    },
    restoraunt: {
      ua: 'Ресторан',
      ru: 'Ресторан',
    },
    PaymentType: {
      ua: 'Тип оплаты',
      ru: 'Тип оплати',
    },
    promo: {
      ua: 'Промокод',
      ru: 'Промокод',
    },
    dataStart: {
      ua: 'Початок акцiї',
      ru: 'Начало акции',
    },
    dataEnd: {
      ua: 'Кiнець акциiї',
      ru: 'Конец акции',
    },
    freeDelivery: {
      ua: 'Безкоштовна доставка',
      ru: 'Бесплатная доставка',
    },
    stockDelivery: {
      ua: 'Знижка при самовивозi',
      ru: 'Скидка при самовывозе',
    },
    label: {
      ua: 'Label',
      ru: 'Label',
    },
    guests: {
      ua: 'Кількість гостей',
      ru: 'Количество гостей',
    },
    addressLabel: {
      ua: "Прев'ю адреси",
      ru: 'Превью адреса',
    },
    email: {
      ua: 'E-mail',
      ru: 'E-mail',
    },
    license: {
      ua: 'Ліцензія',
      ru: 'Лицензия',
    },
    facebook: {
      ua: 'Facebook',
      ru: 'Facebook',
    },
    instagram: {
      ua: 'Instagram',
      ru: 'Instagram',
    },
    longitude: {
      ua: 'Довгота',
      ru: 'Долгота',
    },
    latitude: {
      ua: 'Широта',
      ru: 'Широта',
    },
    restaurant: {
      ua: 'Ресторан',
      ru: 'Ресторан',
    },
    bonuses: {
      ua: 'Бонуси',
      ru: 'Бонусы',
    },
    date: {
      ua: 'Дата',
      ru: 'Дата',
    },
    number: {
      ua: '№',
      ru: '№',
    },
    slug: {
      ua: 'Заголовок',
      ru: 'Заголовок',
    },
    bonusesPercents: {
      ua: 'Сума оплати бонусами в %',

      ru: 'Сумма оплаты бонусами в %',
    },
    timeEnd: {
      ua: 'Залишилось часу',
      ru: 'Осталось времени',
    },
    rate: {
      ua: 'Оцiнка',
      ru: 'Оценка',
    },
    product: {
      ua: 'Продукт',
      ru: 'Продукт',
    },
    stocks: {
      ua: 'Акції',
      ru: 'Акции',
    },
    stocks_main_view: {
      ua: 'Перегляд',
      ru: 'Просмотр',
    },
    stocks_main_change: {
      ua: 'Редагування',
      ru: 'Редактирование',
    },
    feedback: {
      ua: 'Вiдгуки',
      ru: 'Отзывы',
    },
    referals: {
      ua: 'Реферальні коди',
      ru: 'Реферальные коды',
    },
    booking: {
      ua: 'Заявки на бронювання',
      ru: 'Заявки на бронь',
    },
    null: {
      ua: 'Немає',
      ru: 'Нет',
    },
    city: {
      ua: 'Місто',
      ru: 'Город',
    },
    percent: {
      ua: '%',
      ru: '%',
    },
    endStock: {
      ua: 'Акція закінчилась',
      ru: 'Акция закончилась',
    },
    expiresDate: {
      ua: 'Закiнчено',
      ru: 'Завершено',
    },
    admin: {
      ua: 'Адміністратори',
      ru: 'Администраторы',
    },
    message: {
      ua: 'Повідомлення',
      ru: 'Сообщение',
    },
    processing: {
      ua: 'Обробка',
      ru: 'Обработка',
    },
    staff: {
      ua: 'Персонал',
      ru: 'Персонал',
    },
    roles: {
      ua: 'Посади',
      ru: 'Должности',
    },
    users: {
      ua: 'Користувачі',
      ru: 'Пользователи',
    },
    dateOfBirth: {
      ua: 'Дата народження',
      ru: 'Дата рождения',
    },
    orders: {
      ua: 'Замовлення',
      ru: 'Заказы',
    },
    history: {
      ua: 'Архів замовлень',
      ru: 'Архив заказов',
    },
    deleting: {
      ua: 'Bидалення',
      ru: 'Удаление',
    },
    categories: {
      ua: 'Категорiї',
      ru: 'Категории',
    },
    similar: {
      ua: 'Cхоже',
      ru: 'Похожее',
    },
    password: {
      ua: 'Пароль',
      ru: 'Пароль',
    },
    deliveringPrice: {
      ua: 'Ціна доставки',
      ru: 'Цена доставки',
    },
    archived: {
      ua: 'Доданий в архів',
      ru: 'Добавлен в архив',
    },
    favourites: {
      ua: 'Вибране',
      ru: 'Избранное',
    },
    addToSimilar: {
      ua: 'Додати до схожого',
      ru: 'Добавить к похожему',
    },
    averageStars: {
      ua: 'Середня оцінка',
      ru: 'Средняя оценка',
    },
    novelty: {
      ua: 'Новинка',
      ru: 'Новинка',
      true: {
        ua: 'Так',
        ru: 'Да',
      },
      false: {
        ua: 'Ні',
        ru: 'Нет',
      },
    },
    promotionalOffer: {
      ua: 'Акція 2+1',
      ru: 'Акция 2+1',
      true: {
        ua: 'Так',
        ru: 'Да',
      },
      false: {
        ua: 'Ні',
        ru: 'Нет',
      },
    },
    novetly: {
      ua: 'Новинка',
      ru: 'Новинка',
      true: {
        ua: 'Так',
        ru: 'Да',
      },
      false: {
        ua: 'Ні',
        ru: 'Нет',
      },
    },

    starsCount: {
      ua: 'Kількість відгуків',
      ru: 'Kоличество отзывов',
    },
    stock: {
      ua: 'В наявності',
      ru: 'В наличии',
    },
    category: {
      ua: 'Kатегорія',
      ru: 'Kатегория',
    },
    price: {
      ua: 'Ціна',
      ru: 'Цена',
    },
    products: {
      ua: 'Продукти',
      ru: 'Продукты',
    },
    restaurants: {
      ua: 'Ресторани',
      ru: 'Рестораны',
    },
    restaurantsList: {
      ua: 'Список ресторанів',
      ru: 'Список ресторанов',
    },

    productsCount: {
      ua: 'Кількість продуктів',
      ru: 'Kоличество продуктов',
    },
    subCategories: {
      ua: 'Підкатегорії',
      ru: 'Подкатегории',
    },
    subCategoriesCount: {
      ua: 'Кількість підкатегорій',
      ru: 'Kоличество подкатегорий',
    },
    parentCategory: {
      ua: 'Батьківська категорія',
      ru: 'Родительская категория',
    },
    parentProduct: {
      ua: 'Батьківський продукт',
      ru: 'Родительский продукт',
    },
    increment: {
      ua: '№',
      ru: '№',
    },
    fullname: {
      ua: 'ПІБ',
      ru: 'ФИО',
    },
    nutritionalValue: {
      ua: 'Харчова цінність',
      ru: 'Пищевая ценность',
    },
    hidden: {
      ua: 'Прихована',
      ru: 'Cкрытая',
      true: {
        ua: 'Так',
        ru: 'Да',
      },
      false: {
        ua: 'Ні',
        ru: 'Нет',
      },
    },
    login: {
      ua: 'Логін',
      ru: 'Логин',
    },
    role: {
      ua: 'Посада',
      ru: 'Должность',
    },
    sum: {
      ua: 'Сума',
      ru: 'Суммa',
    },
    code: {
      ua: 'Реферальний код',
      ru: 'Реферальный код',
    },
    itemsTotalPrice: {
      ua: 'Сума',
      ru: 'Суммa',
    },
    user: {
      ua: 'Користувач',
      ru: 'Пользователь',
    },
    status: {
      ua: 'Статус',
      ru: 'Статус',
    },
    comment: {
      ua: 'Коментар',
      ru: 'Комментарий',
    },
    delivery: {
      ua: 'Доставка',
      ru: 'Доставка',
    },
    deliveryPrice: {
      ua: 'Ціна за доставку',
      ru: 'Цена за доставку',
    },
    recipient: {
      ua: 'Отримувач',
      ru: 'Получатель',
    },
    call: {
      ua: 'Подзвонити',
      ru: 'Звонить',
    },
    phone: {
      ua: 'Телефон',
      ru: 'Телефон',
    },
    photo: {
      ua: 'Фото',
      ru: 'Фото',
    },
    headerPhoto: {
      ua: 'Фото в шапку додатка',
      ru: 'Фото в шапку приложения',
    },
    dateStart: {
      ua: 'Початок акції',
      ru: 'Начало акции',
    },
    dateEnd: {
      ua: 'Кінець акції',
      ru: 'Конец акции',
    },
    productsList: {
      ua: 'Список товарів',
      ru: 'Список товаров',
    },
    categoriesList: {
      ua: 'Список категорій',
      ru: 'Список категорий',
    },

    name: {
      ua: 'Назва',
      ru: 'Название',
    },
    orderNumber: {
      ua: 'Номер замовлення',
      ru: 'Hомер заказа',
    },
    pickup: {
      ua: 'Самовивіз',
      ru: 'Самовывоз',
    },
    subcategory: {
      ua: 'Підкатегорія',
      ru: 'Подкатегория',
    },
    options: {
      ua: 'Опції',
      ru: 'Опции',
    },
    options_addition: {
      ua: 'Додатково(Вага/шт)',
      ru: 'Дополнительно(Вес/шт)',
    },
    options_name: {
      ua: 'Назва опції',
      ru: 'Название опции',
    },
    options_price: {
      ua: 'Ціна опції',
      ru: 'Цена опции',
    },
    title: {
      ua: 'Назва',
      ru: 'Название',
    },
    calories: {
      ua: 'Калорійність',
      ru: 'Калорийность',
    },
    fats: {
      ua: 'Жири',
      ru: 'Жиры',
    },
    carbohydrates: {
      ua: 'Вуглеводи',
      ru: 'Углеводы',
    },
    proteins: {
      ua: 'Білки',
      ru: 'Белки',
    },
    weight: {
      ua: 'Вага',
      ru: 'Вес',
    },
    description: {
      ua: 'Опис',
      ru: 'Описание',
    },
    amount: {
      ua: 'Кількість',
      ru: 'Количество',
    },
    courier: {
      ua: "Кур'єр",
      ru: 'Курьер',
    },
    withoutComment: {
      ua: '[Без комментарів]',
      ru: '[Без комментариев]',
    },
    type: {
      ua: 'Тип доставки',
      ru: 'Тип доставки',
    },
    address: {
      ua: 'Адрес',
      ru: 'Адрес',
    },
    statusTypes: {
      'payment-paidByCash': {
        ua: 'Оплата готiвкою',
        ru: 'Оплата наличными',
      },
      new: {
        ua: 'Новий',
        ru: 'Новый',
      },
      working: {
        ua: 'Готується',
        ru: 'Готовится',
      },
      delivering: {
        ua: 'Доставляється',
        ru: 'Доставляется',
      },
      rejected: {
        ua: 'Відмовленно',
        ru: 'Отменён',
      },
      done: {
        ua: 'Завершений',
        ru: 'Завершенный',
      },
      'payment-processing': {
        ua: 'В обробці',
        ru: 'В обработке',
      },
      'payment-error': {
        ua: 'Помилка при оплаті',
        ru: 'Ошибка при оплате',
      },
      'payment-paid': {
        ua: 'Сплачено',
        ru: 'Оплачено',
      },
      newByCash: {
        ua: 'новий(готівка)',
        ru: 'новый(наличные)',
      },
      newByOnline: {
        ua: 'новий(онлайн)',
        ru: 'новый(наличные)',
      },
      'payment-paidByOnline': {
        ua: 'Сплата онлайн',
        ru: 'Оплата онлайн',
      },
    },
    created: {
      ua: 'Створений',
      ru: 'Создан',
    },
    updated: {
      ua: 'Оновлений',
      ru: 'Обновлён',
    },
  },
  AuthPage: {
    title: {
      ua: 'Авторизація',
      ru: 'Авторизация',
    },
    loginPlaceholder: {
      ua: 'Логін',
      ru: 'Логин',
    },
    passwordPlaceholder: {
      ua: 'Пароль',
      ru: 'Пароль',
    },
  },
  HomePage: {
    title: {
      ua: 'Головна',
      ru: 'Главная',
    },
  },
  OrdersPage: {
    title: {
      ua: 'Замовлення',
      ru: 'Заказы',
    },
  },
  OrdersDetailPage: {
    title: {
      ua: 'Замовлення',
      ru: 'Заказ',
    },
  },
  CategoriesPage: {
    title: {
      ua: 'Категорії',
      ru: 'Категории',
    },
    pickRestaurant:
    {
      ua: 'Виберіть ресторан',
      ru: 'Выберите ресторан'
    }

  },
  TotalCompletedByStatus: {
    ua: 'Усього завершених за статусами:',
    ru: 'Всего завершенных по статусам:',
  },
  TotalCompletedByReferals: {
    ua: 'Усього завершених за реферальними кодами:',
    ru: 'Всего завершенных по реферальным кодам:',
  },
  TotalCompleted: {
    ua: 'Усього завершених:',
    ru: 'Всего завершенных:',
  },
  TotalReferals: {
    ua: 'Усього реферальних кодів:',
    ru: 'Всего реферальных кодов: ',
  },
  TotalUsers: {
    ua: 'Усього користувачів:',
    ru: 'Всего пользователей:',
  },
  UsedReferals: {
    ua: 'Використані реферальні коди:',
    ru: 'Использованные реферальные коды:',
  },
  WorkingOrders: {
    ua: 'Робочі замовлення:',
    ru: 'Рабочие заказы:',
  },
  DeliveringOrders: {
    ua: 'Замовлення що доставляються:',
    ru: 'Доставляемые заказы:',
  },
  DoneOrders: {
    ua: 'Виконано замовлень:',
    ru: 'Выполнено заказов:',
  },
  DeliveryPage: {
    title: {
      ua: 'Доставка та оплата',
      ru: 'Доставка и оплата',
    },
  },
  NewByCashOrders: {
    ua: 'Нових за касовими ордерами:',
    ru: 'Новых по кассовым ордерам:',
  },
  NewUsers: {
    ua: 'Нових користувачів:',
    ru: 'Новых пользователей: ',
  },
  PaymentErrorOrders: {
    ua: 'Замовлення з помилкою оплати:',
    ru: 'Заказы с ошибкой оплаты: ',
  },
  RejectedOrders: {
    ua: 'Відхилені замовлення:',
    ru: 'Отклоненные заказы:',
  },
  PaymentPaidOrders: {
    ua: 'Сплачені замовлення:',
    ru: 'Оплаченные заказы:',
  },
  CategoriesCreatePage: {
    title: {
      ua: 'Створити категорію',
      ru: 'Cоздать категорию',
    },
  },
  NotificationsPage: {
    title: {
      ua: 'Сторінка повідомлень',
      ru: 'Страница уведомлений',
    },
    new_message: {
      ua: 'Нове замовлення',
      ru: 'Новый заказ',
    },
    save_message: {
      ua: 'Сохранено',
      ru: 'Збережено',
    },
  },
  BookingPage: {
    title: {
      ua: 'Бронювання столиків',
      ru: 'Бронирование столиков',
    },
  },
  ReferalsPage: {
    title: {
      ua: 'Сторінка реферальних кодів',
      ru: 'Страница реферальных кодов',
    },
  },
  StocksCreatePage: {
    title: {
      ua: 'Створити акцію',
      ru: 'Cоздать акцию',
    },
  },
  StocksDetailPage: {
    title: {
      ua: 'Картка акції',
      ru: 'Kарточка акции',
    },
  },
  CategoriesDetailPage: {
    title: {
      ua: 'Картка категорії',
      ru: 'Kарточка категории',
    },
  },
  BookingDetailPage: {
    title: {
      ua: 'Редагувати столик',
      ru: 'Редактировать столик',
    },
  },
  ReferalsCreatePage: {
    title: {
      ua: 'Cтворення реферального коду',
      ru: 'Создание реферального кода',
    },
  },
  ReferalsDetailPage: {
    title: {
      ua: 'Редагування',
      ru: 'Редактирование',
    },
  },
  RestaurantsPage: {
    title: {
      ua: 'Ресторани',
      ru: 'Рестораны',
    },
  },
  ProductsCreatePage: {
    title: {
      ua: 'Створити продукт',
      ru: 'Создать продукт',
    },
  },
  ProductsDetailPage: {
    title: {
      ua: 'Продукт',
      ru: 'Продукт',
    },
  },
  RestaurantsCreatePage: {
    title: {
      ua: 'Створити ресторан',
      ru: 'Создать ресторан',
    },
  },
  BookingCreatePage: {
    title: {
      ua: 'Створити бронь столика',
      ru: 'Создать бронь столика',
    },
  },
  RestaurantsDetailPage: {
    title: {
      ua: 'Ресторан',
      ru: 'Ресторан',
    },
  },
  ProductsPage: {
    title: {
      ua: 'Продукти',
      ru: 'Продукты',
    },
  },
  StocksPage: {
    title: {
      ua: 'Акції',
      ru: 'Акции',
    },
  },
  SalesPage: {
    title: {
      ua: 'Акцiї',
      ru: 'Акции',
    },
  },
  SalesCreatePage: {
    title: {
      ua: 'Створити акцiю',
      ru: 'Создать акцию',
    },
  },
  SalesDetailPage: {
    title: {
      ua: 'Акцiя',
      ru: 'Акция',
    },
  },
  UsersPage: {
    title: {
      ua: 'Користувачі',
      ru: 'Пользователи',
    },
  },
  UsersPageBirthday: {
    title: {
      ua: 'День народження',
      ru: 'День рождения',
    },
  },
  UsersPageBirthdayChange: {
    title: {
      ua: 'Змiнити дату народження',
      ru: 'Изменить дату рождения',
    },
  },
  UsersDetailPage: {
    title: {
      ua: 'Користувач',
      ru: 'Пользователь',
    },
  },
  StaffPage: {
    title: {
      ua: 'Персонал',
      ru: 'Персонал',
    },
  },
  StaffCreatePage: {
    title: {
      ua: 'Створити співробітника',
      ru: 'Создать сотрудника',
    },
  },
  StaffDetailPage: {
    title: {
      ua: 'Співробітник',
      ru: 'Сотрудник',
    },
  },
  RolesPage: {
    title: {
      ua: 'Посади',
      ru: 'Должности',
    },
  },
  RoleCreatePage: {
    title: {
      ua: 'Створити посаду',
      ru: 'Создать должность',
    },
  },
  RoleDetailPage: {
    title: {
      ua: 'Посада',
      ru: 'Должность',
    },
  },
  ArchivePage: {
    title: {
      ua: 'Архів замовлень',
      ru: 'Архив заказов',
    },
  },
  FeedbackPage: {
    title: {
      ua: 'Вiдгуки',
      ru: 'Отзывы',
    },
  },
  SettingsPage: {
    title: {
      ua: 'Налаштування',
      ru: 'Настройки',
    },
    langSwitchLabel: {
      ua: 'Змінити мову :',
      ru: 'Сменить язык :',
    },
  },
  ErrorLength: {
    ua: 'Введіть коректну довжину поля',
    ru: 'Введите корректную длину поля',
  },
  ErrorLinks: {
    ua: 'Введіть коректне посилання в полi',
    ru: 'Введите корректную ссылку',
  },
  ErrorLabelRestaurants: {
    ua: 'Введіть коректне значення',
    ru: 'Введите корректное значение',
  },
  ErrorTypeNumber: {
    ua: 'Введiть числове значення',
    ru: 'Введите числовое значение',
  },
  MinMax: {
    ua: 'Довжина повина бути вiд 3 до 300 символiв',
    ru: 'Длина должна быть от 3 до 300 символов',
  },
  ErrorPhoto: {
    ua: 'Необхідно додати фото',
    ru: 'Необходимо добавить фото',
  },
  ErrorField: {
    ua: 'Оберiть значення поля',
    ru: 'Выберите значение поля',
  },
  ErrorVariations: {
    ua: 'Потрібно додати хоча б одну варіацію',
    ru: 'Нужно добавить хотя бы одну вариацию',
  },
  ErrorAlreadyExist: {
    ua: `Вже існує користувач з таким логіном`,
    ru: `Уже существует пользователь с таким логином`,
  },
  ErrorSize: {
    ua: 'Значення повино бути от 0 до 100',
    ru: 'Значение должно быть от 0 до 100',
  },
  SaveButton: {
    ua: 'Зберегти',
    ru: 'Сохранить',
  },
  ChangePosition: {
    ua: 'Змiнити послiдовнiсть ресторанiв',
    ru: 'Изменить последовательность ресторанов',
  },
  NewsPage: {
    title: {
      ua: 'Новини',
      ru: 'Новости',
    },
  },
  OptionsForProductsPage: {
    title: {
      ua: 'Опції для продуктів',
      ru: 'Опции для продуктов',
    },
    no: {
      ua: 'Немає опцій',
      ru: 'Нету опций',
    },
    CreatePage: {
      ua: 'Створити опцію',
      ru: 'Создать опцию',
    },
    DetailPage: {
      ua: 'Детальна сторінка',
      ru: 'Детальная страница',
    },
    error: {
      length: {
        ua: 'Введіть корректну довжину поля: ',
        ru: 'Выберите корректную длину поля: ',
      },
      name: {
        ua: 'Назва опції',
        ru: 'Название опции',
      },
      photo: {
        ua: 'Необхідно додати фото',
        ru: 'Нужно добавить фото',
      },
      price: {
        ua: 'Ціна ',
        ru: 'Цена',
      },
    },
  },
  NewsCreatePage: {
    title: {
      ua: 'Створити новину',
      ru: 'Создать новость',
    },
  },
  NewsDetailPage: {
    title: {
      ua: 'Картка новини',
      ru: 'Карточка новости',
    },
  },
  RestarauntNotDefined: {
    ua: 'Ресторан не вiдомий',
    ru: 'Ресторан не известен',
  },
  RadioButtons: {
    user: {
      ua: 'Користувач',
      ru: 'Пользователь',
    },
    restoraunt: {
      ua: 'Ресторан',
      ru: 'Ресторан',
    },
    paymentType: {
      ua: 'Тип оплати',
      ru: 'Тип оплаты',
    },
  },
  Items: {
    filterByDate: {
      ua: 'Часовий проміжок для експорта в Excel:',
      ru: 'Часовой промежуток для экспорта в Excel:',
    },
    NoLength: {
      ua: 'Немає замовлень',
      ru: 'Нету заказов',
    },
    Clear: {
      ua: 'Очистити фiльтри',
      ru: 'Очистить фильтры',
    },
  },
  Variants: {
    property: {
      ua: 'Параметр',
      ru: 'Параметр',
    },
  },
  ArrayErrorLength: {
    ua: 'Немає нi одного елемента в',
    ru: 'Нету ни одного элемента в',
  },
}
