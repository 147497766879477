import React, { useState, useEffect, useContext, ChangeEvent } from 'react'

import * as Page from '../../components/Page'
import * as Contexts from '../../contexts'
import * as Components from './components'
import * as CSV from '../../components/ExcelUpload'
import { sortByOptions } from './components/static'
import { Data } from "../../types/items";
import { ConfigBlock } from './components';

import { useHttp } from '../../hooks'

import { config, Translater } from '../../config'

import { IOrder, IPagination, IFilter, TSortBy } from '../../types/items'

export type TGenerateURL = <T = {}>(url: string, query: T) => string

export const generateURL: TGenerateURL = (url, query) =>
  `${url}?${new URLSearchParams(
    Object.entries(query).map(([key, value]) => [key, value.toString()])
  ).toString()}`

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { loading, totalCount, request } = useHttp()
  const [defaultState, setDefaultState] = useState<IOrder[]>([]);
  const [isLoading, toogleLoading] = useState<boolean>(true)
  const [GETLoading, toogleGetLoading] = useState<boolean>(false);
  const [reGET, setReGET] = useState<boolean>(false);
  const [localSearch, setLocalSearch] = useState<Data>({
    field: 'user',
    value: '',
  })
  const [search, setSearch] = useState<Data>({
    field: 'user',
    value: ''
  });
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: 0,
  })
  const [filter, setFilter] = useState<IFilter>({
    sortBy: 'created',
    sortOrder: 'desc',
    dateFrom: '',
    dateTo: '',
  })

  const [primary, setPrimary] = useState<IOrder[]>([])
  const [xslsData, setXSLSData] = useState<any>()

  const Events = {
    OnClear: () => {
      setPrimary(defaultState);
      setSearch({ field: 'user', value: '' });
      setLocalSearch({ field: 'user', value: '' });
      setFilter({ ...filter, dateFrom: '', dateTo: '', sortBy: 'created' });
      setPagination({ ...pagination, page: 1, perPage: 10 });
    },
    inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilter({ ...filter, [e.target.name]: e.target.value })
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      toogleGetLoading(true);
      setPagination({ ...pagination, page: 1, perPage: +e.target.value });
    },
    sortHandler: (sortBy: TSortBy) => {
      console.log(sortBy);
      let sortOrder: 'asc' | 'desc'
      if (filter.sortOrder === 'asc') sortOrder = 'desc'
      else sortOrder = 'asc'

      setFilter({ ...filter, sortBy, sortOrder })
      setPagination({ ...pagination, page: 1 })
    },
    paginateHandler: (index: number) => {
      setPagination({ ...pagination, page: index })
    },
    reloadHandler: () => {
      Callbacks.Fetch()
    },
    sortByHandler: (e: any) => {
      toogleGetLoading(true);
      setFilter({ ...filter, [e.target.name]: e.target.value });
    },
  }

  const getSearchKey = (): string => {
    const { field, value } = search;
    const key = `${value.length ? `&field=${field}&query=${value}` : ''}`;
    // console.log('getSearchKey', key);
    return key;
  };

  const GetSXLS = (responseSXLS: IOrder[]): IOrder[] => {
    const Arr: any[] = [];
    let sum: number = 0
    let products: string

    responseSXLS.forEach((resp: any) => {
      products = ''
      resp.items.forEach((rsp: any) => {
        //  products += ' ' + rsp.name + (rsp.stock) ? '(Акція)' : '';
        products += `${rsp.name}${rsp.stock ? '(Акція)' : ''}`
      })
      Arr.push({
        username: resp.user?.name,
        date: new Date(resp.created).toLocaleDateString(),
        items: products,
        price:
          resp?.price +
          'грн' +
          '(' +
          (resp?.paymentType === 'cash' ? 'Наличные' : 'Онлайн') +
          ')',
      })
      sum += resp.price
    })
    Arr.push({
      sumOfAllOrders: sum,
      countOrders: Arr.length,
    })
    return Arr;
  };

  const Callbacks = {
    Search: async () => {
      try {
        const { page, perPage } = pagination;
        const url =
          generateURL(`${config.API}/orders/search`, {
            page,
            perPage,
            sortOrder: filter.sortOrder,
            sortBy: filter.sortBy,
          }) + `&createdAt[]=${filter.dateFrom ? filter.dateFrom : ''}&createdAt[]=${filter.dateTo ? filter.dateTo : ''}${getSearchKey()}`;

        const response: IOrder[] = await request(url, 'GET', null, {
          Authorization: token as string,
        });
        const Arr: any[] = GetSXLS(response);

        if (response) {
          setXSLSData(Arr);
          setPrimary(response);
        };
      } catch (e) {
        console.log(e);
      } finally {
        toogleGetLoading(false);
      };
    },

    GetReload: async () => {
      try {
        const { page, perPage } = pagination
        const url =
          generateURL(`${config.API}/orders`, {
            page,
            perPage,
            sortOrder: filter.sortOrder,
            sortBy: filter.sortBy,
          }) + `&createdAt[]=${filter.dateFrom ? filter.dateFrom : ''}&createdAt[]=${filter.dateTo ? filter.dateTo : ''}`
        const response: IOrder[] = await request(url, 'GET', null, {
          Authorization: token as string,
        });
        if (response) {
          setDefaultState(response);
        };
      } catch (e) {
        console.log(e);
      };
    },

    Fetch: async () => {
      try {
        const { page, perPage } = pagination
        const url =
          generateURL(`${config.API}/orders`, {
            page,
            perPage,
            sortOrder: filter.sortOrder,
            sortBy: filter.sortBy,
          }) + `&createdAt[]=${filter.dateFrom ? filter.dateFrom : ''}&createdAt[]=${filter.dateTo ? filter.dateTo : ''}`

        const response: IOrder[] = await request(url, 'GET', null, {
          Authorization: token as string,
        })

        const responseSXLS: IOrder[] = await request(
          `${url}&createdAt[]=${filter.dateFrom}&createdAt[]=${filter.dateTo}`, 'GET', null, {
          Authorization: token as string,
        })

        const Arr: any[] = GetSXLS(responseSXLS);

        if (response) {
          setXSLSData(Arr)
          setPrimary(response);
        }
      } catch (e) {
        console.log(e)
      } finally {
        toogleLoading(false)
        toogleGetLoading(false);
      };
    },
  }

  useEffect(() => {
    setPagination({ ...pagination, totalCount })
  }, [totalCount])

  useEffect(() => {
    if (!(search.field.length != 0 && search.value.length != 0)) {
      Callbacks.Fetch()
    };
    Callbacks.GetReload();
  }, [filter, pagination.page, pagination.perPage]);

  useEffect(() => {
    if (search.field.length != 0 && search.value.length != 0) {
      Callbacks.Search();
      toogleGetLoading(true);
    };
  }, [search, filter, pagination]);

  useEffect(() => {
    if (reGET) {
      setReGET(false);
      setXSLSData(GetSXLS(defaultState));
      setPrimary(defaultState);
    };
  }, [reGET])

  if (isLoading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper footer title={Translater.OrdersPage.title[language.slug]}>
      <Page.Header title={Translater.OrdersPage.title[language.slug]} />

      <Page.Search
        setData={setSearch}
        setReGET={setReGET}
        setLocalSearch={setLocalSearch}
        localSearch={localSearch}
      />

      <div className="flex">
        <Page.Filter agePicker form={filter} events={Events} label={Translater.Items.filterByDate[language.slug]} />

        <Page.SortBar
          perPage={pagination.perPage}
          filter={filter}
          selectHandler={Events.selectHandler}
          sortByHandler={Events.sortByHandler}
          sortByOptions={sortByOptions}
        />
      </div>
      {GETLoading ? <Page.Preloader relative />
        : primary.length > 0 ?
          <ConfigBlock
            primary={primary} pagination={pagination}
            Events={Events} xslsData={xslsData}
          />
          : <Page.NoLength />}

      <Page.Footer>
        <Page.Pagination
          linkTo='/orders'
          pagination={pagination}
          paginateHandler={Events.paginateHandler}
          setPagination={setPagination}
        />
      </Page.Footer>
    </Page.Wrapper>
  )
}

export default IndexPage
