import React, { useContext } from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Form from '../../components/Form'
import * as Buttons from '../../components/Buttons'

import { Translater } from '../../config'


const IndexPage: React.FC = () => {

  const { actions: authActions } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  return (
    <Page.Wrapper
      title={Translater.SettingsPage.title[language.slug]}
    >
      <Page.Header
        title={Translater.SettingsPage.title[language.slug]}
      />

      <Form.LabelField
        label={Translater.SettingsPage.langSwitchLabel[language.slug]}
        fontSize='small'
        fontWeight='bold'
      >
        <Form.LanguageToggler />
      </Form.LabelField>

      <Buttons.Container
        logoutHandler={authActions.LOGOUT}
      />

    </Page.Wrapper>
  )
}

export default IndexPage