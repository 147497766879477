import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'
import { useHttp } from '../../hooks'

import {
  config,
  Translater
} from '../../config'

import { IStaff } from '../../types/items'
import { IPagination } from '../../types/items'


const IndexPage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const [primary, setPrimary] = useState<IStaff[]>([])
  const { loading, totalCount, request } = useHttp()

  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: 0
  })

  const Events = {
    reloadHandler: () => {
      Callbacks.Fetch()
    },

    paginateHandler: (page: number) => {
      setPagination({ ...pagination, page })
    },

    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setPagination({ ...pagination, page: 1, perPage: +e.target.value })
    },
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IStaff[] = await request(`${config.API}/admins?page=${pagination.page}&perPage=${pagination.perPage}`,
          'GET', null, {
          Authorization: (token as string)
        })

        if (response) {
          setPrimary(response)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [pagination.page, pagination.perPage])

  useEffect(() => {
    setPagination({ ...pagination, totalCount })
  }, [totalCount])


  if (loading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper
      title={Translater.StaffPage.title[language.slug]}
    >
      <Page.Header
        title={Translater.StaffPage.title[language.slug]}
        enableButton={access.admin?.children?.staff?.rule === 'change'}
        linkTitle={Translater.Buttons.create[language.slug]}
        linkTo='/staff/create'
      />

      <Page.SortBar
          perPage={pagination.perPage}
          selectHandler={Events.selectHandler}
        />

      <Components.Table
        items={primary}
        reloadHandler={Events.reloadHandler}
      />

      <Page.Footer>
        <Page.Pagination
          pagination={pagination}
          setPagination={setPagination}
          paginateHandler={Events.paginateHandler}
          linkTo='/staff'
        />
      </Page.Footer>
    </Page.Wrapper>
  )
}

export default IndexPage
