import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
} from 'react'

import * as Contexts from '../../../contexts'
import * as Buttons from '../../../components/Buttons'
import * as Form from '../../../components/Form'
import * as Page from "../../../components/Page";
import * as Utils from "../../../utils";

import { config, Translater } from '../../../config'
import { useHttp } from '../../../hooks'

import { SHiddenOptions } from '../../../components/Form/static'
import { IAdditions, ICategory, IProduct, IRestaurant, IVariations } from '../../../types/items'
import { IConfigBlockProps } from './types'
import { DefaultButton } from '../../../components/Buttons'


const ConfigBlock: React.FC<IConfigBlockProps> = ({
  data,
  form,
  isValid,
  showPhoto = false,
  multiLangTitles,
  multiLangDescriptions,
  setCategory,
  inputHandler,
  selectHandler,
  setForm,
  textareaHandler,
  isDetailPage = false,
}) => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)



  const { request, totalCount } = useHttp()
  const [weight, setWeight] = useState<string>();
  const [price, setPrice] = useState<string>();

  const [showValue, setShowValue] = useState<boolean>(false)
  const [, setInputValue] = useState<string>('')
  const [, setInputRestaurantValue] = useState<string>('')
  const [categoryOptions, setCategoryOptions] = useState<any[]>([])
  const [restaurantOptions, setRestaurantOptions] = useState<any[]>([])
  const [children, setChildren] = useState<any[]>([])
  const [, setCategorySelectValue] = useState<any>({
    label: '',
    value: '',
  })
  const [, setRestaurantSelectValue] = useState<any>({
    label: '',
    value: '',
  })
  const [adds, setAdds] = useState<IAdditions[]>([]);
  const [ad, setAd] = useState<any>({})
  const [rest, setRest] = useState<any>({});
  const [variantsShow, setVariantsShow] = useState<boolean>(false);
  const [vars, setVars] = useState<IVariations>({
    name: '',
    priceChange: '',
    weightChange: '',
  });
  const [searchSelAds, setSearchSelAds] = useState<string>("");

  const Callbacks = {

    loadAdditionsById: async (id = form.restaurant[0] ? form.restaurant[0] : '0') => {
      try {
        const adds = await request(`${config.API}/addition`, 'GET', null, {
          Authorization: token as string
        });
        setAdds(adds);
        setAd(adds[0]);
      } catch (e) {
        console.log(e);
      }
    }
  }





  const Events = {
    inputHandler: (value: string) => {
      setInputValue(value)
    },
    inputRestaurantHandler: (value: string) => {
      setInputRestaurantValue(value)
    },
    loadChildrenOptions: async () => {
      try {
        if (!!rest) {
          const response: IProduct[] = await request(
            `${config.API}/products`,
            'GET',
            null,
            {
              Authorization: token as string,
            }
          )

          setChildren(response)
        }
      } catch (e) {
        console.log(e)
      }
    },
    loadRestaurantOptions: async () => {
      try {
        const response: IRestaurant[] = await request(
          `${config.API}/restaurants`,
          'GET',
          null,
          {
            Authorization: token as string,
          }
        )
        setRestaurantOptions(response)
        setRest(response[0]);
      } catch (e) {
        console.log(e)
      }
    },
    optionRestaurantHandler: (option: any) => {
      setRestaurantSelectValue(option)
    },
    loadCategoriesOptions: async () => {
      try {
        if (!!rest && !!restaurantOptions?.length) {
          const response: ICategory[] = await request(
            `${config.API}/categories`,
            'GET',
            null,
            {
              Authorization: token as string,
            }
          )

          setCategoryOptions(response);
        }
      } catch (e) {
        console.log(e)
      }
    },
    optionHandler: (option: any) => {
      setCategorySelectValue(option)
      setCategory(option.value)
    },

    buttonHandler: () => {
      setShowValue(!showValue)
    },

    setHandler: (arr: any[]) => {
      console.log(arr);
    },

    arrowsHandler: (idx: number, old: number) => {
      const variations = Utils.changeArrayItemPosition(idx, old, form.variations);
      setForm && setForm({ ...form, variations });
    },
    selectHandler: (e: any) => {
      console.log("E", e.target.value);
    }
  }

  useEffect(() => {
    Events.loadRestaurantOptions()
  }, []);

  useEffect(() => {
    Events.loadCategoriesOptions()
    Events.loadChildrenOptions()
  }, [rest])

  useEffect(() => {
    if (data?.isVariated && data.variations.length) {
      setPrice(data.variations[0].priceChange);
    }

  }, [])

  useEffect(() => {
    if (data?.isVariated && data.variations.length && data.variations[0].weightChange) {
      setWeight(data.variations[0].weightChange);
    }
  }, [])


  useEffect(() => {
    if (form.restaurant) {
      Callbacks.loadAdditionsById()
    } else {
      Callbacks.loadAdditionsById(rest._id)
    }
  }, [rest, form.restaurant]);

  const adsSelectHandler = (ad: any) => {
    setAd(ad);
  };

  const selectRestHandler = (restaurant: string[]) => {
    if (restaurant) {
      setForm && setForm({ ...form, restaurant });
      setRest(restaurant);
    }
  }

  const addOptionHandler = () => {
    if (ad) {
      const additions = form.additions && [...form.additions.filter((i) => i._id != ad._id), ad]
      setForm && setForm({ ...form, additions });
    }
  };

  const deleteAd = (id: string) => {
    if (id != null) {
      setForm && setForm({ ...form, additions: form.additions?.filter((i) => i._id !== id) })
    } else {
      setForm && setForm({ ...form, additions: form.additions?.filter((i) => i != null) });
    }
  }

  const deleteVar = (variation: string) => {
    if (variation != null) {
      if (variation) {
        setForm && setForm({ ...form, variations: form.variations.filter((i) => i.name != variation) });
      }
    } else {
      setForm && setForm({ ...form, variations: form.variations?.filter((i) => i != null) });
    }
  }

  const addVar = () => {
    if (
      !form.variations.find((i) => i.name == vars.name)
      && (vars.name.length > 0 && vars.priceChange.length > 0)
    ) {
      const newVar = vars.weightChange && vars.weightChange.length > 0 ?
        vars : { priceChange: vars.priceChange, name: vars.name };
      setForm && setForm({ ...form, variations: [...form.variations, newVar] });
      setVars({ name: '', priceChange: '', weightChange: '' })
    }
  }

  const varsHandler = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setVars({ ...vars, [name]: value });
  }


  useEffect(() => {
    if (!form.restaurant.length && restaurantOptions.length) {
      selectRestHandler(restaurantOptions.map(element => {
        return element._id;
      }));
    }

  });


  useEffect(() => {
    form.isVariated == 'true' && setForm && setForm({ ...form, price: 0, weight: "0" });

  }, [form.isVariated])

  useEffect(() => {
    console.log("DAATA", data);
  }, []);

  useEffect(() => {
    if (typeof form.isVariated == 'boolean') {


      if (form.isVariated) {
        setForm && setForm({ ...form, isVariated: 'true' });
      }
      else {
        setForm && setForm({ ...form, isVariated: 'false' });

      }
    }
  });
  return (


    <Fragment>
      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.title[language.slug]}
        text={access.products?.rule !== 'change' ? form.name : ''}
      >
        {access.products?.rule === 'change' ? (
          multiLang ? (
            <Form.MultiLang
              minLength={2}
              multiLangTitles={multiLangTitles}
              placeholder={Translater.Placeholders.minTwo[language.slug]}
              inputHandler={inputHandler}
            />
          ) : (
            <Form.Input
              name='name'
              placeholder={Translater.Placeholders.minTwo[language.slug]}
              isValid={isValid.name}
              value={form.name}
              inputHandler={inputHandler}
            />
          )
        ) : data && data.name}
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.description[language.slug]}
        text={access.products?.rule !== 'change' ? form.description : ''}
      >
        {access.products?.rule === 'change' ? (
          multiLang ? (
            <Form.MultiLang
              minLength={2}
              multiLangDescriptions={multiLangDescriptions}
              placeholder={Translater.Placeholders.minTwo[language.slug]}
              textareaHandler={textareaHandler}
            />
          ) : (
            <Form.Textarea
              name='description'
              placeholder={Translater.Placeholders.minTwo[language.slug]}
              isValid={isValid.description}
              value={form.description}
              textareaHandler={textareaHandler}
            />
          )
        ) : data && data.description}
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.hasVariations[language.slug]}
        text={
          access.products?.rule !== 'change'
            ? form.isVariated
              ? Translater.TableTitles.hidden.true[language.slug]
              : Translater.TableTitles.hidden.false[language.slug]
            : ''
        }
      >
        {access.products?.rule === 'change' ? (
          <Form.Select
            name='isVariated'
            options={SHiddenOptions}
            value={form.isVariated as string}
            selectHandler={selectHandler}
          />
        ) : data?.isVariated}
      </Form.LabelField>

      {(form.isVariated != 'true') ? (

        <>
          <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.weight[language.slug]}
            text={access.products?.rule !== 'change' ? weight + " г" : ''}
          >
            {access.products?.rule === 'change' ? (
              multiLang ? (
                <Form.MultiLang
                  minLength={2}
                  multiLangTitles={multiLangTitles}
                  placeholder={Translater.Placeholders.minTwo[language.slug]}
                  inputHandler={inputHandler}
                />
              ) : (
                <Form.Input
                  name='weight'
                  type='number'
                  placeholder={Translater.Placeholders.minTwo[language.slug]}
                  isValid={isValid.weight}
                  value={form.weight}
                  inputHandler={inputHandler}
                />
              )
            ) : null}
          </Form.LabelField>



          <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.price[language.slug]}
            text={access.products?.rule !== 'change' ? `${price} грн` : ''}
          >
            {access.products?.rule === 'change' ? (
              <Form.Input
                type='number'
                name='price'
                value={form.price}
                isValid={isValid.price}
                inputHandler={inputHandler}
              />
            ) : null}
          </Form.LabelField>

        </>) : (

        <>

          {form.variations.map((i, idx) => (
            <Page.Item
              deleteField='name'
              idx={idx}
              key={idx}
              data={{ ...i, priceChange: `${i.priceChange}грн`, weightChange: i.weightChange ? `${i.weightChange}г` : undefined }}
              deleteHandler={deleteVar}
              fields={['name', 'priceChange', 'weightChange']}
              arrows
              arrowsHandler={Events.arrowsHandler}
            />
          ))}



          <div>
            <div style={{ marginTop: 20 }}>
              <Form.LabelField
                fontSize='small'
                label={Translater.Variants.property[language.slug]}
              >
                <Form.Input
                  inputHandler={varsHandler}
                  value={vars.name}
                  name="name"
                />
              </Form.LabelField>

              <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.weight[language.slug]}
              >
                <Form.Input
                  inputHandler={varsHandler}
                  value={vars.weightChange}
                  type='number'
                  name="weightChange"
                />
              </Form.LabelField>

              <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.price[language.slug]}
              >
                <Form.Input
                  inputHandler={varsHandler}
                  value={vars.priceChange}
                  type='number'
                  name="priceChange"
                />
              </Form.LabelField>

              <DefaultButton
                title={Translater.Buttons.add[language.slug]}
                buttonHandler={addVar}
              />
            </div>
          </div>


        </>

      )}



      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.hidden[language.slug]}
        text={
          access.products?.rule !== 'change'
            ? form.hidden
              ? Translater.TableTitles.hidden.true[language.slug]
              : Translater.TableTitles.hidden.false[language.slug]
            : ''
        }
      >
        {access.products?.rule === 'change' ? (
          <Form.Select
            name='hidden'
            options={SHiddenOptions}
            value={form.hidden as string}
            selectHandler={selectHandler}
          />
        ) : null}
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.novelty[language.slug]}
        text={
          access.products?.rule !== 'change'
            ? form.novelty
              ? Translater.TableTitles.novelty.true[language.slug]
              : Translater.TableTitles.novelty.false[language.slug]
            : ''
        }
      >
        {access.products?.rule === 'change' ? (
          <Form.Select
            name='novelty'
            options={SHiddenOptions}
            value={form.novelty as string}
            selectHandler={selectHandler}
          />
        ) : null}
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.restaurants[language.slug]}
      >


        <Fragment>
          <Page.SearchSelect
            isMulti
            placeholder={Translater.Placeholders.searchByRests[language.slug]}
            searchField='title'
            data={restaurantOptions}
            setSwitch={selectRestHandler}
            multiField='_id'
            value={isDetailPage ? form.restaurant : null}
          />
        </Fragment>
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.category[language.slug]}
        text={
          access.products?.rule !== 'change' && data
            ? (data.category as ICategory).title
              ? ''
              : '-'
            : ''
        }
      >
        <Fragment>
          {access.products?.rule !== 'change' && data ? (
            <Buttons.Link
              title={(data.category as ICategory).title}
              linkTo={`/categories/${(data.category as ICategory)._id}`}
            />
          ) : null}

          {access.categories?.rule === 'change' ? (
            <>
              <Page.SearchSelect
                placeholder={Translater.Placeholders.category[language.slug]}
                data={categoryOptions}
                searchField='title'
                isInvalid={!isValid.category}
                setSwitch={(category: any) => setForm && setForm({ ...form, category })}
                value={isDetailPage ? { label: form.category.title, title: form.category._id } : null}
              />
            </>
          ) : null}
        </Fragment>
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.parentProduct[language.slug]}
        text={
          access.products?.rule !== 'change' && data
            ? (data as IProduct).name
              ? ''
              : '-'
            : ''
        }
      >
        <Fragment>
          {access.products?.rule !== 'change' && data ? (
            <Buttons.Link
              title={(data as IProduct).name}
              linkTo={`/products/${(data as IProduct)._id}`}
            />
          ) : null}
          {access.categories?.rule === 'change' ? (
            <>
              <Form.Dropdown
                disabledValue={Translater.Placeholders.parentProduct[language.slug]}
                items={children.filter(item => item._id != form._id)}
                searchField='name'
                item={form.parent}
                name="parent"
                changeHandler={selectHandler}
              />
            </>
          ) : null}
        </Fragment>
      </Form.LabelField>

      {access.products?.rule === 'change' ? (
        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.photo[language.slug]}
        >
          <Form.Input
            type='file'
            name='photo'
            isValid={isValid.photo}
            inputHandler={inputHandler}
          />
        </Form.LabelField>
      ) : null}

      {showPhoto ? (
        <Form.LabelField
          fontSize='small'
          label={
            access.products?.rule !== 'change'
              ? Translater.TableTitles.photo[language.slug]
              : ''
          }
        >
          <img
            alt='Product'
            src={`${config.serverURL}${(data as IProduct).photo}`}
            style={{ objectFit: 'cover', width: 350, height: 250 }}
          />
        </Form.LabelField>
      ) : null}




      {form.additions?.map((i, idx) => (
        <Page.Item
          data={i}
          fields={['name', 'price', 'weight']}
          idx={idx}
          key={idx}
          deleteHandler={deleteAd}
          deleteField='_id'
        />
      ))}

      {/* {showValue ? (
        <DefaultButton
          title={Translater.Buttons.add[language.slug]}
          buttonHandler={addOptionHandler}
        />
      ) : null} */}
      {showValue && adds ? (
        <div>
          {adds && (
            <div className='adds'>
              <Page.SearchSelect
                placeholder={Translater.Placeholders.searchAddition[language.slug]}
                data={adds}
                searchField='name'
                setSwitch={adsSelectHandler}
                value={searchSelAds}
                setValue={setSearchSelAds}
              />
              <DefaultButton

                title={Translater.Buttons.add[language.slug]}
                buttonHandler={addOptionHandler}
              />

            </div>
          )}

        </div>
      ) : null}



      {access.products?.rule === 'change' && (<DefaultButton
        title={Translater.Buttons.addOptions[language.slug]}
        buttonHandler={Events.buttonHandler}
      />)}









    </Fragment>
  )
}

export default ConfigBlock
