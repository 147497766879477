import React, { Fragment, useContext } from 'react'

import * as Contexts from '../../contexts'
import * as Form from '.'

import { Translater } from '../../config'

import { IDateBlockProps } from './types'


const DateBlock: React.FC<IDateBlockProps> = ({
  updated,
  created
}) => {

  const { language } = useContext(Contexts.LanguageContext)

  return (
    <Fragment>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.updated[language.slug]}
        text={new Date(updated).toLocaleDateString()}
      />

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.created[language.slug]}
        text={new Date(created).toLocaleDateString()}
      />
    </Fragment>
  )
}

export default DateBlock