import React, { Fragment, useContext } from 'react'

import * as Contexts from '../../../../contexts'
import * as Form from '../../../../components/Form'
import * as Buttons from '../../../../components/Buttons'

import { Translater } from '../../../../config'

import { IDetailBlockProps } from './types'

const DetailBlock: React.FC<IDetailBlockProps> = ({ data }) => {
  const { language } = useContext(Contexts.LanguageContext)

  return (
    <Form.LabelField
      fontSize='small'
      label={Translater.TableTitles.favourites[language.slug]}
      text={
        !data.favourites.length
          ? Translater.TableTitles.null[language.slug]
          : ''
      }
    >
      {data.favourites?.map((child, index) => (
        <Fragment key={child._id}>
          <Buttons.Link title={child.name} linkTo={`/products/${child._id}`} />

          {index + 1 !== data.favourites?.length ? (
            <span className='comma'>,</span>
          ) : null}
        </Fragment>
      ))}
    </Form.LabelField>
  )
}

export default DetailBlock
