import React, { useState, useContext, useMemo, useEffect } from "react";
import { ISearchProps } from "./types";
import * as Form from "../Form";
import { Translater } from '../../config'
import * as Contexts from '../../contexts'
import { Data } from "../../types/items";

const Search: React.FC<ISearchProps> = ({
    setData,
    setReGET = (val: boolean) => undefined,
    // searchData
    localSearch, 
    setLocalSearch
}) => {
    const [isValidSearch, setIsValidSearch] = useState<boolean>(true);
    const { language } = useContext(Contexts.LanguageContext)
    const radioButtons = useMemo(() => [
        { id: 0, slug: 'user', value: Translater.TableTitles.field[language.slug] },
        { id: 1, slug: 'increment', value: Translater.TableTitles.incRest[language.slug] },
        { id: 2, slug: 'restaurant', value: Translater.TableTitles.restaurant[language.slug] },
        { id: 3, slug: "comment", value: Translater.TableTitles.comment[language.slug] },
    ], []); 
    /* const [search, setSearch] = useState<Data>({
        field: '',
        value: '',
    }); */
 
    const Events = {
        inputSearchHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            setIsValidSearch(value.length >= 3);
            //@ts-ignore
            setLocalSearch((state) => ({ ...state, value }));
            //@ts-ignore
            setData((state) => ({ ...state, value: '' }));
            if (value.length === 0) {
                setReGET(true);
                setIsValidSearch(true);
            };
        },
        radioSearchHandler: (e: any) => {
            const field = e.target.value;
            console.log(field);
            //@ts-ignore
            setLocalSearch(() => ({ field, value: '' }));
            //@ts-ignore
            setData((state) => ({ ...state, value: '' }));
            setIsValidSearch(true);
            setReGET(true);
        },
    };

    useEffect(() => {
        console.log("SearchComponentMiddleware", localSearch);
        if (isValidSearch && localSearch.value.length >= 3) {
            setData(localSearch);
        };
    }, [localSearch]);

    return (
        <div className="search-box">
            <Form.Input 
                type='text'
                name='search'
                placeholder={Translater.Placeholders.search[language.slug]}
                addClassName='order-input'
                value={localSearch.value}
                inputHandler={Events.inputSearchHandler}
                isValid={isValidSearch}
            />
            <Form.RadioButton
                data={radioButtons}
                desc=""
                radioButtonHandler={Events.radioSearchHandler}
            />
        </div>
    );
};

export default Search;