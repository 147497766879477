import React, {
    useState,
    useEffect,
    useContext,
    ChangeEvent,
    KeyboardEvent
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'
import {useHttp} from '../../hooks'

import {
    config,
    Translater
} from '../../config'

import {
    IPagination,
    IFilter,
    TSortBy,
    IClient
} from '../../types/items'


const IndexPage: React.FC = () => {

    const {token} = useContext(Contexts.AuthContext)
    const {language} = useContext(Contexts.LanguageContext)

    const {loading, totalCount, request} = useHttp()

    const [pagination, setPagination] = useState<IPagination>({
        page: 1,
        perPage: 50,
        totalCount: 0
    })
    const [filter, setFilter] = useState<IFilter>({
        sortBy: 'dateOfBirth',
        sortOrder: 'asc',
    })
    const [searchValue, setSearchValue] = useState<string>('')

    const [usersWithBirthDay, setUsersWithBirthDay] = useState<IClient[]>([])

    const Events = {
        searchHandler: (e: ChangeEvent<HTMLInputElement>) => {
            setSearchValue(e.target.value)
        },
        pressHandler: (e: KeyboardEvent) => {
            if (e.key === 'Enter') Callbacks.Fetch()
        },
        selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
            setPagination({...pagination, page: 1, perPage: +e.target.value})
        },
        sortHandler: (name: TSortBy) => {
            let sortOrder: 'asc' | 'desc'
            if (filter.sortOrder === 'asc') {
                sortOrder = 'desc'
            } else {
                sortOrder = 'asc'
            }
            setFilter({
                sortBy: name,
                sortOrder
            })

            setPagination({...pagination, page: 1})
        },
        paginateHandler: (index: number) => {
            setPagination({...pagination, page: index})
        }
    }

    const Callbacks = {
        Fetch: async () => {
            try {
                const response: IClient[] = await request(`${config.API}/users/?perPage=${pagination.perPage}&page=${pagination.page}&query=${searchValue}&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}`,
                    'GET', null, {
                        Authorization: (token as string)
                    })

                if (response) {
                    console.log("usersBirthDays", response);
                    const arrWithUsersBirthDays: IClient[] = []
                    response.map((item) => {
                        if (item.dateOfBirth) {
                            arrWithUsersBirthDays.push(item)
                        }
                    })
                    setUsersWithBirthDay(arrWithUsersBirthDays)
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    useEffect(() => {
        setPagination({...pagination, totalCount})
    }, [totalCount])

    useEffect(() => {
        Callbacks.Fetch()
    }, [filter.sortBy, filter.sortOrder, pagination.perPage, pagination.page])

    if (loading || !usersWithBirthDay) return <Page.Preloader/>

    return (
        <Page.Wrapper
            footer
            title={Translater.Sidebar.birthdays[language.slug]}
        >
            <Page.Header
                title={Translater.Sidebar.birthdays[language.slug]}
            />

            <Page.SortBar
                searchBar
                filter={filter}
                value={searchValue}
                perPage={pagination.perPage}
                pressHandler={Events.pressHandler}
                inputHandler={Events.searchHandler}
                selectHandler={Events.selectHandler}
            />

            <Page.WaitWrapper
                data={usersWithBirthDay.length > 0}
                loader={false}
                no={Translater.TableTitles.noInfo[language.slug]}
            >
                <Components.Table
                    items={usersWithBirthDay}
                    sortHandler={Events.sortHandler}
                />
            </Page.WaitWrapper>

            <Page.Footer>
                <Page.Pagination
                    linkTo='/usersBirthdays'
                    pagination={pagination}
                    paginateHandler={Events.paginateHandler}
                    setPagination={setPagination}
                />
            </Page.Footer>
        </Page.Wrapper>
    )
}

export default IndexPage
