import React, { useContext } from 'react'

import * as Contexts from '../../contexts'
import * as Components from './components'

import { useDocTitle } from '../../hooks'
import { Translater } from '../../config'




const IndexPage: React.FC = () => {

  const { status } = useContext(Contexts.OnlineContext)
  const { language } = useContext(Contexts.LanguageContext)

  useDocTitle(Translater.AuthPage.title[language.slug])

  return (
    <div className='auth-wrapper'>

      <Components.LoginCard />

    </div>
  )
}

export default IndexPage