import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { IAsyncSearchProps } from "./types";
import { Translater } from "../../config";
import * as Contexts from "../../contexts";
interface Option {
    value: string
    label: string
}
const SearchSelect: React.FC<IAsyncSearchProps> = ({
    placeholder,
    data,
    searchField,
    isMulti = false,
    setSwitch,
    value = null,
    multiField,
    dataField,
    defaultValue,
    isInvalid = false,
    height = 46,
    width
}) => {




    const [searches, setSearches] = useState<Option[]>([]);
    const [val, setVal] = useState<any | any[]>(null);

    const { language } = useContext(Contexts.LanguageContext);

    const Functions = {
        convertToTypeArray: (arr: any): Option[] => {
            const Arr = [];
            for (let item of arr) {
                const value = item?._id;
                const label = dataField ? item[dataField] : item[searchField] || '';

                Arr.push({
                    value,
                    label,
                });
            }
            return Arr;
        },
    }

    const Events = {
        optionHandler: (val: any) => {
            if (val) {
                if (!isMulti) {
                    // eslint-disable-next-line array-callback-return
                    setSwitch(data.find((i) => {
                        const field = dataField ? i[dataField] : i[searchField];
                        if (field == val.label) {
                            return i;
                        }
                    }));
                } else {
                    const arr: any[] = [];
                    data.forEach((i) => {
                        for (let v of val) {
                            const field = dataField ? i[dataField] : i[searchField];
                            if (field === v.label) {
                                arr.push(multiField ? i[multiField] : i)
                            }
                        }
                    });
                    setSwitch(arr);
                }
                setVal(val);
            }
        }
    }

    useEffect(() => {

        const convertedData = Functions.convertToTypeArray(data);



        if (!searches.length && convertedData.length) {
            setSearches(convertedData);
        }


        if (!val && value) {



            if (Array.isArray(value) && value.length && value[0]._id) {
                const values: any[] = [];

                value.forEach((element) => {

                    values.push({ value: element._id, label: element.title });

                })

                setVal(values);
            }



            if (value.title || value.value) {

                setVal(value);
            }

            if (typeof value === 'string' || value instanceof String) {
                console.log('VAL', val, "VALUE", convertedData);

                let check = false;


                convertedData.forEach((element) => {
                    if (element.value === value) {
                        check = true;
                        setVal(element);
                    }
                });

                // Restaurant details page
                if (!check) {
                    convertedData.forEach((element) => {
                        if (element.label === value) {
                            setVal(element);
                        }
                    });
                }


            }


        }
    });


    return (
        <>
            <Select
                isMulti={isMulti}
                isSearchable
                defaultValue={searches.filter((item) => item.label === defaultValue)[0]}
                styles={{
                    control: () => ({
                        display: 'flex',
                        border: isInvalid ? '1px solid red' : '1px solid #e6e6e6',
                        borderRadius: 5,
                        outline: 0,
                        height,
                        width

                    }),
                    input: () => ({
                        fontSize: 16,
                    })
                }}
                options={searches}
                closeMenuOnSelect={false}
                placeholder={placeholder}
                noOptionsMessage={() => Translater.Placeholders.noOptionsMessage[language.slug]}
                value={val}
                onChange={Events.optionHandler}

                className={'invalid'}
            />

        </>
    )
}

export default SearchSelect;
