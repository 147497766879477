import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from 'react'

import * as Components from './components'
import * as Contexts from '../../contexts'
import * as Buttons from '../../components/Buttons'
import * as Page from '../../components/Page'
import * as Form from '../../components/Form'

import { TForm, TRestaurantValidationSchema } from './types'
import { IRestaurant, ICity, IRole, IStaff } from '../../types/items'
import { useParams, useHistory } from 'react-router-dom'
import { config, Translater } from '../../config'
import { useHttp } from '../../hooks'
import { useValidation } from '../../hooks'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { TRoleOptionsState, TRoleRest } from './components/types'

const DetailPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const history = useHistory()
  const { id } = useParams() as any
  const { loading, request } = useHttp()

  const [primary, setPrimary] = useState<IRestaurant | null>(null)
  const [headerPhoto, setHeaderPhoto] = useState<File | null>(null)
  const [photo, setPhoto] = useState<File | null>(null)
  const [roleGlobal, setRoleGlobal] = useState<TRoleRest>()
  const [roleStr, setRoleStr] = useState<string | null>('')
  const [roleOptions, setRoleOptions] = useState<TRoleOptionsState[]>()
  const [headerColor, setHeaderColor] = useState<string>("");
  const [form, setForm] = useState<TForm>({
    city: '',
    login: '',
    title: '',
    password: '',
    email: '',
    phone: '',
    label: '',
    latitude: '',
    facebook: '',
    longitude: '',
    instagram: '',
    headerColor: '',
  })

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const schema = useMemo<TRestaurantValidationSchema>(
    () => ({
      city: {
        condition: !!form.city,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.city[language.slug]}`,
      },
      title: {
        condition: form.title.length >= 3,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.title[language.slug]}`,
      },
      phone: {
        condition: true,

        // condition: form.phone.length >= 3,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.password[language.slug]}`,
      },
      email: {
        condition: true,

        // condition: form.email.length >= 3,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.password[language.slug]}`,
      },
      facebook: {
        condition: true,

        // condition: form.facebook.length >= 3,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.password[language.slug]}`,
      },
      instagram: {
        condition: true,

        // condition: form.instagram.length >= 3,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.password[language.slug]}`,
      },
      label: {
        condition: true,

        // condition: form.label.length >= 3,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.password[language.slug]}`,
      },
      latitude: {
        condition: true,

        // condition: !!form.latitude,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.password[language.slug]}`,
      },
      longitude: {
        condition: true,

        // condition: !!form.longitude,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.password[language.slug]}`,
      },
      headerColor: {
        condition: form.headerColor.length > 0,
        error: `${Translater.ErrorLength[language.slug]}: 
        ${Translater.Placeholders.colorPicker[language.slug]}`
      }
    }),
    [form, Translater, language]
  )
  const { errors, validationSchema, validation } = useValidation(schema)


  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name
      const value = e.target.value
      if (e.target.name === 'photo') {
        setPhoto(e.target?.files?.length ? e.target?.files[0] : null)
      }

      else setForm({ ...form, [name]: value })
    },
    inputFileHandler: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.name === 'headerPhoto') {
        setHeaderPhoto(e.target?.files?.length ? e.target?.files[0] : null)
      }
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    setCity: (city: string) => {
      setForm({ ...form, city })
    },
    setRole: (role: string) => {
      // setForm({ ...form, role: role })
    },
    saveHandler: async () => {
      try {
        await validation()

        Callbacks.Save()
      } catch (e) {
        toogleIsAlertOpen(true)
        console.log('createHandler => e', e)
      }
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IRestaurant = await request(
          `${config.API}/restaurants/${id}`,
          'GET',
          null,
          {
            Authorization: token as string,
          }
        )


        const {
          title,
          city,
          headerColor,
          contacts: {
            email,
            phone,
            social: { facebook, instagram },
            address: { latitude, longitude, label },
          },
        } = response


        if (response) {

          const newForm = {
            ...form,
            title,
            city: (city as ICity)?._id! || (city as string),
            email,
            label,
            phone,
            facebook,
            instagram,
            latitude,
            longitude,
            headerColor,
          }

          setForm(newForm)
          if (response) {
            setPrimary(response)
          }

        }
      } catch (e) {
        console.log(e)
      }
    },
    Save: async () => {
      try {
        const {
          title,
          city,
          login,
          email,
          phone,
          label,
          latitude,
          facebook,
          longitude,
          instagram,
        } = form

        const contacts: IRestaurant['contacts'] = {
          phone,
          email,
          social: {
            facebook,
            instagram,
          },
          address: {
            label,
            latitude,
            longitude,
          },
        }





        const data: FormData = new FormData()

        data.append('contacts', JSON.stringify(contacts))

        if (photo) data.append('restPhoto', photo)
        if (headerPhoto) data.append('headerPhoto', headerPhoto)


        data.append('title', title)
        data.append('city', city)

        if (form.headerColor.length > 0) {
          await request(`${config.API}/restaurants/${primary?._id}/headerColor`, "PATCH",
            { headerColor: form.headerColor }, {
            Authorization: token as string
          });
        }

        const response = await request(
          `${config.API}/restaurants/${primary?._id}`,
          'POST',
          data,
          {
            Authorization: token as string,
          }
        )


        if (response) history.push('/restaurants')
      } catch (e) {
        console.log(e)
      }
    },
    Delete: async () => {
      try {
        await request(`${config.API}/restaurants/${id}`, 'DELETE', null, {
          Authorization: token as string,
        })

        history.push('/restaurants')
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [id])


  console.log('loading', loading, 'primary', primary)
  if (loading || !primary) return <Page.Preloader />
  return (
    <Page.Wrapper title={primary.title}>
      <Page.Header
        backButtonTitle={Translater.RestaurantsDetailPage.title[language.slug]}
        backButtonLink='/restaurants'

      />

      <Buttons.Container
        disabled={loading}
        saveHandler={
          access.restaurants?.rule === 'change' ? Events.saveHandler : false
        }
        deleteHandler={
          access.restaurants?.rule === 'change' ? Events.deleteHandler : false
        }
      />

      <Components.ConfigBlock
        form={form}
        photo={photo}
        data={primary}
        headerPhoto={headerPhoto}
        isValid={validationSchema}
        //@ts-ignore
        role={roleGlobal}
        roleStr={roleStr as string}
        setCity={Events.setCity}
        setRole={Events.setRole}
        setForm={setForm as any}
        getOptions={setRoleOptions as any}
        setHeaderColor={setHeaderColor}
        inputFileHandler={Events.inputFileHandler}
        inputHandler={Events.inputHandler}
      />

      <Components.ContactsBlock
        form={form}
        isValid={validationSchema}
        inputHandler={Events.inputHandler}
      />

      <Form.DateBlock
        updated={primary.updated as string}
        created={primary.created as string}
      />
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity='error'>
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default DetailPage
