import React, { Fragment, useState, useEffect, useContext } from 'react'

import * as Contexts from '../../../contexts'
import * as Buttons from '../../../components/Buttons'
import * as Form from '../../../components/Form'

import { config, Translater } from '../../../config'
import { useHttp } from '../../../hooks'

import { IRole, ICity, IRestaurant } from '../../../types/items'
import { IConfigBlockProps } from './types'

import AsyncSelect from 'react-select/async'

const ConfigBlock: React.FC<IConfigBlockProps> = ({
    data,
    form,
    role,
    photo,
    isValid,
    setCity,
    setRole,
    headerPhoto,
    getOptions,
    setForm,
    inputHandler,
    inputFileHandler
}) => {
    const { token } = useContext(Contexts.AuthContext)
    const { access } = useContext(Contexts.UserContext)
    const { language } = useContext(Contexts.LanguageContext)

    const { request } = useHttp()

    const [inputValue, setInputValue] = useState<string>('')
    const [, setInputRoleValue] = useState<string>('')
    const [options, setOptions] = useState<any[]>([])
    const [roleOptions, setRoleOptions] = useState<any[]>([])
    const [citySelectValue, setCitySelectValue] = useState<any>({
        label: '',
        value: '',
    })
    const [, setCategorySelectValue] =
        useState<string>('hello')

    const Events = {
        inputHandler: (value: string) => {
            setInputValue(value)
        },
        loadOptions: async (inputValue: string) => {
            try {
                const response: ICity[] = await request(
                    `${config.API}/cities/?name=${inputValue}`,
                    'GET',
                    null,
                    {
                        Authorization: token as string,
                    }
                )
                const formatted: ICity[] = []
                if (response.length) {
                    for (const item of response) {
                        const option = {
                            value: item._id as string,
                            label: item.name as string,
                        }
                        formatted.push(option as any)
                    }
                }
                setOptions(formatted)
                return formatted
            } catch (e) {
                console.log(e)
            }
        },
        colorPickerHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
            setForm && setForm({ ...form, headerColor: e.target.value });
        },
        optionHandler: (option: any) => {
            setCitySelectValue(option)
            setCity(option.value)
        },
    }
    const RolesEvents = {
        inputRoleHandler: (value: string) => {
            setInputRoleValue(value)
            setCategorySelectValue(value)
        },

        loadRolesOptions: async () => {
            try {
                const response: IRole[] = await request(
                    `${config.API}/roles?title=`,
                    'GET',
                    null,
                    {
                        Authorization: token as string,
                    }
                )
                const formatted: IRole[] = []
                getOptions && getOptions!(response)
                setRoleOptions(response)
                return formatted
            } catch (e) {
                console.log(e)
            }
        },
        optionRoleHandler: (option: IRole) => {
            if (option && option._id) {
                console.log(option)
                setRole(option._id);
                setForm && setForm({ ...form, role: option.title })
            }
        },
    }

    useEffect(() => {
        RolesEvents.loadRolesOptions()
    }, [])

    const Styles = {
        control: () => ({
            display: 'flex',
            border: '1px solid #e6e6e6',
            borderRadius: 5,
            outline: 0,
            height: 47
        }),
        input: () => ({
            fontSize: 16,
        }),
    }

    useEffect(() => {
        setInputRoleValue((data?.role as IRole)?.title)
        setInputValue((data?.city as ICity)?.name)
    }, [data])

    return (
        <Fragment>
            <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.title[language.slug]}
                text={access.restaurants?.rule !== 'change' ? form.title : ''}
            >

                {access.restaurants?.rule === 'change' ? (
                    <Form.Input
                        name='title'
                        value={form.title}
                        isValid={isValid.title}
                        placeholder={Translater.Placeholders.minThree[language.slug]}
                        inputHandler={inputHandler}
                    />
                ) : null}
            </Form.LabelField>

            <Form.LabelField
                fontSize='small'
                label={Translater.Placeholders.colorPicker[language.slug]}
            >
                <Form.DefaultColorPicker
                    onHandler={Events.colorPickerHandler}
                    defaultValue={data?.headerColor}
                />
            </Form.LabelField>

            {photo ? (
                <Form.LabelField
                    fontSize='small'
                    label={
                        access.restaurants?.rule !== 'change'
                            ? Translater.TableTitles.photo[language.slug]
                            : ''
                    }
                >
                    {photo ? <img
                        alt='Restaurant'
                        src={URL.createObjectURL(photo)}
                        style={{ width: 350, height: 250, objectFit: 'cover' }}
                    /> : null}
                </Form.LabelField>
            ) : (
                <Form.LabelField
                    fontSize='small'
                    label={
                        access.restaurants?.rule !== 'change'
                            ? Translater.TableTitles.photo[language.slug]
                            : ''
                    }
                >
                    {data?.photo ? <img
                        alt='Restaurant'
                        src={data?.photo ? `${config.serverURL}${(data as IRestaurant).photo}` : ''}
                        style={{ width: 350, height: 250, objectFit: 'cover' }}
                    /> : null}
                </Form.LabelField>
            )
            }
            {access.restaurants?.rule === 'change' ? (
                <Form.LabelField
                    fontSize='small'
                    label={Translater.TableTitles.photo[language.slug]}
                >
                    <Form.Input
                        type='file'
                        name='photo'
                        // isValid={!!photo}
                        inputHandler={inputHandler}
                    />
                </Form.LabelField>
            ) : null}



            <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.city[language.slug]}
                text={
                    access.restaurants?.rule !== 'change' && data
                        ? (data.city as ICity).name
                            ? ''
                            : '-'
                        : ''
                }
            >
                <Fragment>
                    {access.restaurants?.rule !== 'change' && data ? (
                        <Buttons.Link
                            title={(data?.city as ICity).name}
                            linkTo={`/cities/${(data?.city as ICity)._id}`}
                        />
                    ) : null}

                    {access.restaurants?.rule === 'change' ? (
                        <AsyncSelect
                            cacheOptions
                            loadingMessage={() =>
                                Translater.Placeholders.loadingMessage[language.slug]
                            }
                            noOptionsMessage={() =>
                                Translater.Placeholders.noOptionsMessage[language.slug]
                            }
                            inputValue={inputValue}
                            styles={Styles}
                            className={`${!isValid.city && 'invalid'}`}
                            value={citySelectValue}
                            defaultOptions={options}
                            onInputChange={Events.inputHandler}
                            onChange={Events.optionHandler}
                            loadOptions={Events.loadOptions}
                        />
                    ) : null}
                </Fragment>
            </Form.LabelField>

            {/* <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.login[language.slug]}
                text={access.restaurants?.rule !== 'change' ? role?.login : ''}
            >
                {access.restaurants?.rule === 'change' ? (
                    <Form.Input
                        name='login'
                        value={form.login}
                        isValid
                        placeholder={Translater.Placeholders.minFour[language.slug]}
                        inputHandler={inputHandler}
                    />
                ) : null}
            </Form.LabelField> */}

            {/* <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.password[language.slug]}
                text={access.restaurants?.rule !== 'change' ? form.password : ''}
            >
                {access.restaurants?.rule === 'change' ? (
                    <Form.Input
                        isValid
                        type='password'
                        name='password'
                        value={form.password}

                        placeholder={Translater.Placeholders.minThree[language.slug]}
                        inputHandler={inputHandler}
                        onFocus={() => {
                            setForm && setForm((prev: TForm) => ({ ...prev, password: '' }))
                        }}
                    />
                ) : null}
            </Form.LabelField> */}

            {/* <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.role[language.slug]}
                text={
                    access.restaurants?.rule !== 'change' && data
                        ? (data.role as IRole).title
                            ? ''
                            : '-'
                        : ''
                }
            >
                <Fragment>
                    {access.restaurants?.rule !== 'change' && data ? (
                        <Buttons.Link
                            title={(data.role as IRole).title}
                            linkTo={`/roles/${(data.role as IRole)._id}`}
                        />
                    ) : null} */}

            {/* {access.restaurants?.rule === 'change' ? (
                        <>
                            <Page.SearchSelect
                                placeholder={Translater.Placeholders.minTwo[language.slug]}
                                value={form.role}
                                defaultValue={form.role}
                                data={roleOptions}
                                searchField='title'
                                setSwitch={RolesEvents.optionRoleHandler}
                            />
                        </>
                    ) : null}
                </Fragment>
            </Form.LabelField> */}
        </Fragment>
    )
}

export default ConfigBlock
