import React, { Fragment } from 'react'

import * as Contexts from '.'


const Collection: React.FC = ({ children }) => {


  return (
    <Contexts.OnlineContextProvider>
      <Contexts.ConfigContextProvider>
        <Contexts.AuthContextProvider>
          <Contexts.WSContextProvider>
            <Contexts.LanguageContextProvider>
              <Contexts.UserContextProvider>
                <Contexts.SidebarContextProvider>
                  <Contexts.ModalContextProvider>
                    <Fragment>
                      {children}
                    </Fragment>
                  </Contexts.ModalContextProvider>
                </Contexts.SidebarContextProvider>
              </Contexts.UserContextProvider>
            </Contexts.LanguageContextProvider>
            </Contexts.WSContextProvider>
        </Contexts.AuthContextProvider>
      </Contexts.ConfigContextProvider>
    </Contexts.OnlineContextProvider>
  )
}

export default Collection
