import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Buttons from '../../components/Buttons'
import * as Components from './components'

import { config, Translater } from '../../config'
import { useHttp } from '../../hooks/http.hook'
import { useHistory } from 'react-router-dom'
import { useValidation } from '../../hooks'
import { TCreateValidationSchema } from './types'

const CreatePage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const history = useHistory()
  const { loading, request } = useHttp()

  const [isValid, toggleValid] = useState({
    phone: false,
    guests: false,
    date: false,
    // photo: false,
  })

  const [form, setForm] = useState<any>({
    phone: '',
    guests: '',
    date: '',
  })
  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const schema = useMemo<TCreateValidationSchema>(
    () => ({
      phone: {
        condition: form.phone && form.phone.length >= 5,
        error: `
          ${Translater.ErrorLength[language.slug]}`
      },
      guests: {
        condition: !!form.guests,
        error: Translater.ErrorPhoto[language.slug],
      },
      date: {
        condition: !!form.date,
        error: Translater.ErrorPhoto[language.slug],
      },
    }),
    [form, Translater, language]
  )

  const { errors, validationSchema, validation } = useValidation(schema)
  const Events = {
    inputHandler: (
      e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
      //@ts-ignore
      if (e.target.type === 'file')
        //@ts-ignore
        setForm({ ...form, photo: e.target.files[0] })
      /*  else if (e.target.name.split('[')[1]) {
                setMultiLangTitles({
                    ...multiLangTitles, [e.target.name]: e.target.value
                })
            } */ else setForm({ ...form, [e.target.name]: e.target.value })
    },
    /*  selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
            setForm({ ...form, [e.target.name]: e.target.value })
        }, */
    setCity: (city: string) => {
      setForm({ ...form, city })
    },
    /*  setRole: (role: string) => {
              setForm({ ...form, role })
          }, */
    createHandler: async () => {
      try {
        await validation()
        Callbacks.Create()
      } catch (e) {
        toogleIsAlertOpen(true)
        console.log(e)
      }
    },
  }

  const Callbacks = {
    FetchRestaurants: async () => {
      const restaurants = await request(`${config.API}/restaurants`, 'GET', null, {
        Authorization: token as string,
      })

      return restaurants;
    },
    Create: async () => {
      try {
        console.log(form)
        // const data = new FormData()
        const restaurant = await Callbacks.FetchRestaurants();

        const data = {
          "guests": form.guests,
          "phone": form.phone,
          "date": form.date,
          "restaurant": restaurant && restaurant[0]._id
        };


        const response = await request(`${config.API}/booking`, 'POST', data, {
          Authorization: token as string,
        })

        if (response) {
          history.goBack()
        }
      } catch (e) {
        console.log(e)
      }
    },
  }

  // useEffect(() => {
  //     if (form.price.length > 0) toggleValid({ ...isValid, price: true })
  //     else toggleValid({ ...isValid, price: false })
  // }, [form.price])

  /*    useEffect(() => {
        if (form.photo) toggleValid({ ...isValid, photo: true })
        else toggleValid({ ...isValid, photo: false })
    }, [form.photo]) */

  return (
    <Page.Wrapper title={Translater.BookingCreatePage.title[language.slug]}>
      <Page.Header
        backButtonTitle={Translater.BookingCreatePage.title[language.slug]}
      />

      <Buttons.Container
        disabled={loading}
        createHandler={Events.createHandler}
      />

      <Components.ConfigBlock
        isCreate
        form={form}
        isValid={validationSchema}
        //  setCity={Events.setCity}
        inputHandler={Events.inputHandler}
      //  selectHandler={Events.selectHandler}
      />
    </Page.Wrapper>
  )
}

export default CreatePage
