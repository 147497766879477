import React, { useState, useEffect, useContext, ChangeEvent } from 'react'

import * as Components from './components'
import * as Contexts from '../../contexts'
import * as Buttons from '../../components/Buttons'
import * as Page from '../../components/Page'
import * as Form from '../../components/Form'

import { useHistory, useParams } from 'react-router-dom'
import { config, Translater } from '../../config'
import { useHttp } from '../../hooks/http.hook'

const DetailPage: React.FC = () => {
  const { id } = useParams() as any
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const history = useHistory()
  const { loading, request } = useHttp()
  const [loader, toogleLoader] = useState<boolean>(false);

  const [primary, setPrimary] = useState<any | null>(null)
  const [isValid, toggleValid] = useState({
    phone: false,
    guests: false,
    date: false,
  })

  const [form, setForm] = useState<any>({
    guests: '',
    phone: '',
    date: '',
  })

  const Events = {
    inputHandler: (
      e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
      //@ts-ignore
      if (e.target.type === 'file')
        //@ts-ignore
        setForm({ ...form, photo: e.target.files[0] })
      else setForm({ ...form, [e.target.name]: e.target.value })
    },
    /*  selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
              setForm({ ...form, [e.target.name]: e.target.value })
          }, */
    setCity: (city: string) => {
      setForm({ ...form, city })
    },
    /*  setRole: (role: string) => {
              setForm({ ...form, role })
          }, */
    // saveHandler: () => {
    //     if (isValid.guests) Callbacks.Save()
    // },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },
  }

  const Callbacks = {
    Fetch: async () => {
      toogleLoader(true)
      try {
        const response: any = await request(
          `${config.API}/booking/${id}`,
          'GET',
          null,
          {
            Authorization: token as string,
          }
        )
        console.log(response)

        if (response) {
          setPrimary(response)
        }
      } catch (e) {
        console.log(e)
      } finally {
        toogleLoader(false);
      }
    },
    // Save: async () => {
    //     try {
    //         const data = new FormData()
    //         console.log(form)
    //
    //         data.append('guests', form.guests)
    //         data.append('phone', form.phone)
    //         data.append('date', form.date)
    //         const response = await request(`${config.API}/booking/${primary?._id}`, 'POST', data, {
    //             Authorization: (token as string),
    //         })
    //
    //         if (response) {
    //             history.goBack()
    //         }
    //     } catch (e) {
    //         console.log(e)
    //     }
    // },
    Delete: async () => {
      try {
        await request(`${config.API}/booking/${id}`, 'DELETE', null, {
          Authorization: token as string,
        })

        history.push('/booking')
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [id])

  useEffect(() => {
    if (primary)
      setForm({
        ...form,
        guests: primary.guests,
        phone: primary.phone,
        date: primary.date,
      })
  }, [primary])

  // useEffect(() => {
  //     if (form.price > 0) toggleValid({ ...isValid, price: true })
  //     else toggleValid({ ...isValid, price: false })
  // }, [form.price])

  /*    useEffect(() => {
            if (form.photo) toggleValid({ ...isValid, photo: true })
            else toggleValid({ ...isValid, photo: false })
        }, [form.photo]) */

  return (
    <Page.Wrapper 
      title={Translater.BookingDetailPage.title[language.slug]}
    >
      <Page.WaitWrapper
        loader={loader}
        data={primary}
        withoutNoLength
      >
        <Page.Header
          backButtonTitle={Translater.BookingDetailPage.title[language.slug]}
        />
        
        <Buttons.Container
          disabled={loading}
          // saveHandler={Events.saveHandler}
          deleteHandler={Events.deleteHandler}
        /> 

        <Components.ConfigBlock
          isCreate
          form={form}
          isValid={isValid}
          //  setCity={Events.setCity}
          inputHandler={Events.inputHandler}
          //  selectHandler={Events.selectHandler}
        />

        <Form.DateBlock
          updated={primary.updated as string}
          created={primary.created as string}
        />
      </Page.WaitWrapper>
    </Page.Wrapper>
  )
}

export default DetailPage
