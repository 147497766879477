import React, { useContext } from 'react'

import * as Contexts from '../../../../contexts'
import * as Table from '../../../../components/Table'

import { useHistory } from 'react-router-dom'
import { useHttp } from '../../../../hooks/http.hook'
import { config, Translater } from '../../../../config'
import { ITrackProps } from './types'

const Track: React.FC<ITrackProps> = ({
  data,
  order,
  reloadHandler,
  restaurants
}) => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { request } = useHttp()
  const history = useHistory()

  const Events = {
    deleteHandler: () => {
      if (access.orders?.children?.deleting?.rule === 'yes') {
        const answer = window.confirm(Translater.Alert.delete[language.slug])
        if (answer) Callbacks.Delete()
      }
    },
    editHandler: () => {
      if (access.orders?.rule !== 'false') {
        history.push(`/news/${data._id}`)
      }
    }
  }

  const Callbacks = {
    Delete: async () => {
      try {
        await request(`${config.API}/news/${data._id}`, 'DELETE', null, {
          Authorization: (token as string),
        })

        reloadHandler()
      } catch (e) {
        console.log(e)
      }
    },
  }

  const Functions = {
    getRestaraunt: () => {
      let title = "";
      for (const rest of restaurants) {
        //@ts-ignore
        if (data?.restaurants[0] === rest._id) {
          title = rest.title;
        };
      };
      return title.length ? title : Translater.RestarauntNotDefined[language.slug];
    },
    Convert: () => {
      return { __html: data.body };
    },
  };


  return (
    <Table.Track
      type='news'
    >
      <Table.Cell
        text={`${order}`}
      />
      <Table.Cell
        type='image'
        imageURL={`${config.serverURL}${data.photo}`}
      />

      <Table.Cell
        text={data.title}
      />

      <Table.Cell
        //danger={Functions.Convert()}
        text={data.body} 
      />
 
      {restaurants ? (
        <Table.Cell
          text={Functions.getRestaraunt()}
        />
      ) : null}

      <Table.Cell
        date={data.updated ? data.updated : data.created}
      />

      <Table.Cell
        type='control'
        editButton={access.stocks?.rule !== 'false'}
        deleteButton={access.stocks?.rule === 'change'}
        editHandler={Events.editHandler}
        deleteHandler={Events.deleteHandler}
      />

    </Table.Track>
  )
}


export default Track