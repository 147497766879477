import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'
import * as Buttons from '../../components/Buttons'

import { useHttp } from '../../hooks/http.hook'
import { config, Translater } from '../../config'
import { useParams, useHistory } from 'react-router-dom'
import {
  TMutliLangTitles,
  TPossibleMultiLangTitle,
} from '../../types/multiLang'
import { useValidation } from '../../hooks'
import { INewsForm, TDetailValidationSchemaNews, TNews } from './types'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

const DetailPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { id } = useParams() as any
  const history = useHistory()
  const { loading, request } = useHttp()

  const [news, setNews] = useState<TNews | null>(null)
  const [isValid, toggleValid] = useState({
    name: false,
    photo: false,
    productsList: true,
    dateStart: false,
    dateEnd: false,
  })

  const [form, setForm] = useState<INewsForm>({
    title: '',
    photo: '',
    body: '',
    restaurants: [],
    updated: null,
  })

  const [multiLangValues, setMultiLangValues] = useState<TMutliLangTitles>({
    'title[EN]': '',
    'title[RU]': '',
    'title[UA]': '',
  })

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const schema = useMemo<TDetailValidationSchemaNews>(
    () => ({
      title: {
        condition: form.title.length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.name[language.slug]}`,
      },

      body: {
        condition: form.body.length >= 2,
        error: `${Translater.ErrorLength[language.slug]}:`
      },

      photo: {
        condition: !!form.photo,
        error: `
        ${Translater.ErrorLength[language.slug]}:`,
      },

      restaurant: {
        condition: !!form.restaurants,
        error: `
        ${Translater.ErrorField[language.slug]}:
        ${Translater.TableTitles.restaurantsList[language.slug]}`,
      },
    }),
    [form, Translater, language]
  )
  const { errors, validationSchema, validation } = useValidation(schema)

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.type === 'file' && e.target.files)
        setForm({ ...form, photo: e.target.files[0] })
      else if (e.target.name.split('[')[1]) {
        setMultiLangValues({
          ...multiLangValues,
          [e.target.name]: e.target.value,
        })
      } else setForm({ ...form, [e.target.name]: e.target.value })

    },

    editorHandler: (body: any, editor: any) => {
      setForm({ ...form, body });
    },

    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: [e.target.value] })
    },

    saveHandler: async () => {
      try {
        await validation()

        Callbacks.Save()
      } catch (e) {
        toogleIsAlertOpen(true)
        console.log('createHandler => e', e)
      }
    },

    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: TNews = await request(
          `${config.publicAPI}/news/${id}`,
          'GET',
          null,
          {
            Authorization: token as string,
          }
        )

        if (response) {
          console.log(response);
          setForm({
            ...form,
            title: response.title,
            photo: response.photo,
            body: response.body,
            updated: response.updated,
            restaurants: response.restaurants,
          })
          setNews(response)
        }
      } catch (e) {
        console.log(e)
      }
    },
    Save: async () => {
      try {
        const data = new FormData()

        if (multiLang) {
          for (let key in multiLangValues) {
            data.append(key, multiLangValues[key as TPossibleMultiLangTitle])
          }
        } else data.append('title', form.title);

        data.append('body', form.body);



        form.restaurants.forEach(rest => {
          data.append('restaurant[]', rest);
        });


        if (typeof form.photo !== "string") {
          data.append('photo', form.photo);
        };
        console.log("FORM", form);
        const response = await request(
          `${config.API}/news/${id}`,
          'PATCH',
          data,
          {
            Authorization: token as string,
          }
        )

        if (response) history.push('/news')
      } catch (e) {
        console.log(e)
      }
    },

    Delete: async () => {
      try {
        await request(`${config.API}/news/${id}`, 'DELETE', null, {
          Authorization: token as string,
        })

        history.push('/news')
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [id])

  useEffect(() => {

    if (multiLang) {
      let isRight = true

      for (let key in multiLangValues) {
        if (
          multiLangValues[key as TPossibleMultiLangTitle].length >= 2 &&
          isRight
        ) {
          toggleValid({ ...isValid, name: true })
          isRight = true
        } else {
          isRight = false
          toggleValid({ ...isValid, name: false })
        }
      }
    } else {
      if (form.title.length >= 2) toggleValid({ ...isValid, name: true })
      else toggleValid({ ...isValid, name: false })
    }
  }, [form.title, multiLangValues])

  if (loading || !news) return <Page.Preloader />

  return (
    <Page.Wrapper title={news.title}>
      <Page.Header
        backButtonTitle={Translater.NewsDetailPage.title[language.slug]}
        backButtonLink="/news"

      />

      <Buttons.Container
        disabled={loading}
        saveHandler={
          access.stocks?.rule === 'change' ? Events.saveHandler : false
        }
        deleteHandler={
          access.stocks?.rule === 'change' ? Events.deleteHandler : false
        }
      />

      <Components.ConfigBlock
        setParentID={() => { }}
        data={news}
        form={form}
        //@ts-ignore
        isValid={validationSchema}
        multiLangValues={multiLangValues}
        inputHandler={Events.inputHandler}
        selectHandler={Events.selectHandler}
        editorHandler={Events.editorHandler}
        setForm={setForm}
      />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity='error'>
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default DetailPage
