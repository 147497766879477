import React, { useState, useEffect, useContext, createContext } from 'react'

import {
  WSState,
  IWSContext,
  WSMessageData,
  WSContextActions,
} from '../types/contexts/ws'

import * as Contexts from '../contexts'
import { config } from '../config'
import { TMessage } from '../types/items'

const WSContext = createContext<IWSContext>({
  ws: null,
  messages: [],
  isConnected: false,
  actions: {
    DISCONNECT: () => {},
    RECONNECT: () => {},
  },
})

export default WSContext

export const WSContextProvider: React.FC = ({ children }) => {
  const { token } = useContext(Contexts.AuthContext)
  const { modules } = useContext(Contexts.ConfigContext)

  const [ws, updateWS] = useState<WSState | null>(null)
  const [messages, setMessages] = useState<TMessage[]>([])
  const [isConnected, toggleConnected] = useState<boolean>(false)

  const actions: WSContextActions = {
    DISCONNECT: () => {
      console.log('wsContext ➤ actions ➤ DISCONNECT')
      ws?.websocket?.close()
    },
    RECONNECT: () => {
      console.log('wsContext ➤ actions ➤ RECONNECT')
      ws?.websocket?.close()
      updateWS((prev) => ({
        _increment: prev?._increment || 0,
        websocket: new WebSocket(config.ws),
      }))
    },
  }

  const handleOnMessage = {
    switch: (data: WSMessageData | null) => {
      switch (data?.type) {
        case 'ORDER':
          break
        case 'USER':
          break
        default:
          break
      }
    },
  }
  const _actions = {
    sendToken: (token: string, isUseEffect: boolean) => {
      let wsRequestData = JSON.stringify({
        type: 'admin',
        token,
      })
      console.log('Websocket sendData()', { wsRequestData, isUseEffect })
      if (ws?.websocket.readyState) ws?.websocket.send(wsRequestData)
    },
    onOpen: () => {
      console.log('Websocket Callback: onOpen()', {
        token,
      })
      if (token) {
        _actions.sendToken(token, false)
      }
      toggleConnected(true)
    },
    onMessage: (event: MessageEvent) => {
      console.log('Websocket Callback: onMessage()', {
        event,
        strData: event.data,
        objData: JSON.parse(event.data),
      })
      event.data &&
        handleOnMessage.switch(JSON.parse(event.data) as WSMessageData)
      console.log('Websocket Callback: onMessage()', { event })
      setMessages((prev) => {
        return [...prev, JSON.parse(event.data)]
      })
    },
    onError: (event: Event) => {
      toggleConnected(false)
      console.log('Websocket Callback: onError()', { event })
      setTimeout(actions.RECONNECT, 5000)
    },
    onClose: (event: CloseEvent) => {
      updateWS(null)
      toggleConnected(false)
      console.log('Websocket Callback: onClose()', { event })
      setTimeout(actions.RECONNECT, 5000)
    },
  }

  useEffect(() => {
    if (token && modules.ws) _actions.sendToken(token, true)
  }, [token])

  useEffect(() => {
    if (ws?.websocket && modules.ws) {
      console.log('Websocket update all listeners')
      ws.websocket.onopen = _actions.onOpen
      ws.websocket.onerror = _actions.onError
      ws.websocket.onclose = _actions.onClose
      ws.websocket.onmessage = _actions.onMessage
    }
  }, [ws?.websocket])

  useEffect(() => {
    if (modules.ws)
      updateWS({
        websocket: new WebSocket(config.ws),
        _increment: 0,
      })
    return ws?.websocket?.close
  }, [modules.ws])

  return (
    <WSContext.Provider
      value={{
        ws,
        isConnected,
        actions,
        messages,
      }}
    >
      {children}
    </WSContext.Provider>
  )
}
