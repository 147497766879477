import React, { Fragment, useState, useContext, useEffect } from 'react'

import * as Contexts from '../../../contexts'
import * as Buttons from '../../../components/Buttons'
import * as Form from '../../../components/Form'
import * as Page from '../../../components/Page'

import { config, Translater } from '../../../config'
import { useHttp } from '../../../hooks'

import { SHiddenOptions } from '../../../components/Form/static'
import { ICategory, IRestaurant } from '../../../types/items'
import { IConfigBlockProps } from './types'

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  data,
  form,
  isValid,
  multiLangValues,
  setParentID,
  inputHandler,
  selectHandler,
  setRestaurant,
  setForm,
}) => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { request } = useHttp()

  const [, setInputValue] = useState<string>('')
  const [, setInputRestaurantValue] = useState<string>('')
  const [categoryOptions, setCategoryOptions] = useState<any[]>([])
  const [restaurantOptions, setRestaurantOptions] = useState<any[]>([])
  const [, setCategorySelectValue] = useState<any>({
    label: '',
    value: '',
  })
  const [, setRestaurantSelectValue] = useState<any>({
    label: '',
    value: '',
  })
  const [, setRest] = useState<any>({})

  const Events = {
    inputHandler: (value: string) => {
      setInputValue(value)
    },
    inputRestaurantHandler: (value: string) => {
      setInputRestaurantValue(value)
    },
    loadRestaurantOptions: async () => {
      try {
        const response: IRestaurant[] = await request(
          `${config.API}/restaurants`,
          'GET',
          null,
          {
            Authorization: token as string,
          }
        )

        if (response && form._id) {
          setForm({ ...form, restaurant: [response[0]._id] });
        }
        setRestaurantOptions(response);


      } catch (e) {
        console.log(e)
      }
    },
    loadCategoriesOptions: async () => {
      try {
        function createUrl() {

          const url = `${config.API}/categories?children=1`
          const arr = restaurantOptions.map((item) => {
            // @ts-ignore
            return item._id;
          });


          const string =
            arr && replaceAll(arr?.toString(), ',', '&restaurant[]=')
          function replaceAll(
            string: string,
            search: string,
            replace: string
          ): string {
            return string.split(search).join(replace)
          }

          return url;
        }

        if (restaurantOptions && restaurantOptions.length > 0) {
          const response: ICategory[] = await request(createUrl(), 'GET', null, {
            Authorization: token as string,
          })
          console.log("RESPONSE", response);
          setCategoryOptions(response)

        }


      } catch (e) {
        console.log(e)
      }
    },
    optionHandler: (option: any) => {
      setCategorySelectValue(option)
      setParentID(option.value)
    },
    optionRestaurantHandler: (option: any) => {
      setRestaurantSelectValue(option)
      setRestaurant && setRestaurant(option);
    },
  }

  useEffect(() => {
    Events.loadRestaurantOptions()
  }, [data?.restaurant])
  useEffect(() => {
    Events.loadCategoriesOptions()
  }, [restaurantOptions])

  useEffect(() => {
    if (data?.parentCategory) {
      setInputValue((data?.parentCategory as any)._id)
    }
  }, [data])




  return (
    <Fragment>
      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.title[language.slug]}
        text={access.categories?.rule !== 'change' ? form.title : ''}
      >
        {access.categories?.rule === 'change' ? (
          multiLang ? (
            <Form.MultiLang
              placeholder={Translater.Placeholders.minTwo[language.slug]}
              minLength={2}
              multiLangTitles={multiLangValues}
              inputHandler={inputHandler}
            />
          ) : (
            <Form.Input
              name='title'
              placeholder={Translater.Placeholders.minTwo[language.slug]}
              isValid={isValid.title}
              value={form.title}
              inputHandler={inputHandler}
            />
          )
        ) : null}
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.restaurants[language.slug]}
      >
        {console.log('data', data?.restaurant)}
        <Fragment>
          <Page.SearchSelect
            isMulti
            placeholder={Translater.Placeholders.searchByRests[language.slug]}
            searchField='title'
            data={restaurantOptions}
            setSwitch={(e) => {
              Events.optionRestaurantHandler(e);
            }}
            multiField='_id'
            value={data?.restaurant}
          />

        </Fragment>
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.hidden[language.slug]}
        text={
          access.categories?.rule !== 'change'
            ? form.hidden
              ? Translater.TableTitles.hidden.true[language.slug]
              : Translater.TableTitles.hidden.false[language.slug]
            : ''
        }
      >
        {access.categories?.rule === 'change' ? (
          <Form.Select
            name='hidden'
            options={SHiddenOptions}
            value={form.hidden as string}
            selectHandler={selectHandler}
          />
        ) : null}
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.parentCategory[language.slug]}
        text={
          access.categories?.rule !== 'change' && data
            ? data.parentCategory?.title
              ? ''
              : '-'
            : ''
        }
      >

        {access.categories?.rule !== 'change' &&
          data &&
          data.parentCategory?.title
          ? (
            <Buttons.Link
              title={data.parentCategory?.title}
              linkTo={`/categories/${data.parentCategory._id}`}
            />
          ) : null}
        {access.categories?.rule === 'change' ? (
          <Form.Dropdown
            disabledValue={Translater.TableTitles.parentCategory[language.slug]}
            items={categoryOptions.filter((item) => item._id != data?._id)}
            item={form.parent_id}
            name='parent_id'
            changeHandler={selectHandler}
          />
        ) : null}
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.position[language.slug]}
        text={access.categories?.rule !== 'change' ? form.title : ''}
      >
        {access.categories?.rule === 'change' ? (
          multiLang ? (
            <Form.MultiLang
              placeholder={Translater.Placeholders.minTwo[language.slug]}
              minLength={2}
              multiLangTitles={multiLangValues}
              inputHandler={inputHandler}
            />
          ) : (
            <Form.Input
              name='position'
              placeholder={Translater.Placeholders.minTwo[language.slug]}
              isValid={isValid.position}
              value={form.position}
              inputHandler={inputHandler}
            />
          )
        ) : null}
      </Form.LabelField>

      {access.categories?.rule === 'change' ? (
        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.photo[language.slug]}
        >
          <Form.Input
            type='file'
            name='photo'
            isValid={isValid.photo}
            inputHandler={inputHandler}
          />
        </Form.LabelField>
      ) : null}
    </Fragment>
  )
}

export default ConfigBlock
