export const config = {
    height: 500,
    menubar: false,
    plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount image',
    ],
    toolbar:
        'undo redo | formatselect | fontsizeselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | image code',
    image_title: false,
    automatic_uploads: false,
    images_upload_url: 'postAcceptor.php',
};