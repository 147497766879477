import React, { useContext } from 'react'

import * as Contexts from '../../../../contexts'
import * as Table from '../../../../components/Table'

import { useHttp } from '../../../../hooks'
import { useHistory } from 'react-router-dom'
import { Translater, config } from '../../../../config'
import { IRole } from '../../../../types/items'
import { ITrackProps } from './types'


const Track: React.FC<ITrackProps> = ({
  data,
  order,
  reloadHandler
}) => {


  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const history = useHistory()
  const { request } = useHttp()

  const Events = {
    editHandler: () => {
      if (access.admin?.children?.staff?.rule !== 'false') {
        history.push(`/staff/${data._id}`)
      }
    },
    deleteHandler: () => {
      if (access.admin?.children?.staff?.rule === 'change') {
        const answer = window.confirm(Translater.Alert.delete[language.slug])
        if (answer) Callbacks.Delete()
      }
    }
  }

  const Callbacks = {
    Delete: async () => {
      try {
        await request(`${config.API}/admins/${data._id}`, 'DELETE', null, {
          Authorization: (token as string),
        })

        reloadHandler()
      } catch (e) {
        console.log(e)
      }
    },
  }

  return (
    <Table.Track
      type='staff'
    >
      <Table.Cell
        text={`${order}`}
      />

      <Table.Cell
        text={data.fullname}
      />

      <Table.Cell
        text={data.login}
      />

      <Table.Cell
          // @ts-ignore
          text={data.role ? data.role.title : 'нет'}
      />

      <Table.Cell
        text={new Date((data.updated as string)).toLocaleDateString()}
      />

      <Table.Cell
        text={new Date((data.created as string)).toLocaleDateString()}
      />

      <Table.Cell
        type='control'
        editButton={access.users?.rule !== 'false'}
        editHandler={Events.editHandler}
        deleteButton={access.admin?.children?.staff?.rule === 'change'}
        deleteHandler={Events.deleteHandler}
      />
    </Table.Track>
  )
}

export default Track
