import { INavLink } from './types'
import { IOption, Radio } from '../../types/items'

export const SNavLinks: INavLink[] = [
  { linkTo: '/admin', slug: 'home' },
  { linkTo: '/orders', slug: 'orders' },
  { linkTo: '/categories', slug: 'categories' },
  { linkTo: '/products', slug: 'products' },
  { linkTo: '/options-for-products', slug: 'options' },
  { linkTo: '/booking', slug: 'booking' },
  // { linkTo: '/bookingDetails', slug: 'bookingDetails' },
  { linkTo: '/referals', slug: 'referals' },
  { linkTo: '/restaurants', slug: 'restaurants' },
  { linkTo: '/feedback', slug: 'feedback' },
  // { linkTo: '/sales', slug: 'sales' },
  { linkTo: '/users', slug: 'users' },
  { linkTo: '/usersBirthdays', slug: 'usersBirthdays' },
  { linkTo: '/staff', slug: 'staff' },
  { linkTo: '/roles', slug: 'roles' },
  { linkTo: '/archive', slug: 'history' },
  { linkTo: '/delivery', slug: 'delivery' },
  { linkTo: '/notifications', slug: 'notifications' },
  { linkTo: '/stocks', slug: 'stocks' },
  { linkTo: '/news', slug: "news" },
  { linkTo: '/settings', slug: 'settings' },
]

export const SOptions: IOption[] = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' },
  { value: 40, label: '40' },
  { value: 50, label: '50' },
]