import React, {
  Fragment,
  useState,
  useEffect
} from 'react'

import * as Form from '.'

import { IMultiLangProps } from './types'
import {
  TPossibleMultiLangTitle,
  TPossibleMultiLangDescriptions
} from '../../types/multiLang'


const MultiLang: React.FC<IMultiLangProps> = ({
  minLength,
  placeholder,
  multiLangTitles,
  multiLangDescriptions,
  inputHandler,
  textareaHandler
}) => {

  const [inputs, setInputs] = useState<TPossibleMultiLangTitle[]>([])
  const [textareas, setTextareas] = useState<TPossibleMultiLangDescriptions[]>([])

  useEffect(() => {
    if (multiLangTitles) {
      const arr: TPossibleMultiLangTitle[] = []
      for (let key in multiLangTitles) {
        arr.push((key as TPossibleMultiLangTitle))
      }
      setInputs(arr)
    } else if (multiLangDescriptions) {
      const arr: TPossibleMultiLangDescriptions[] = []
      for (let key in multiLangDescriptions) {
        arr.push((key as TPossibleMultiLangDescriptions))
      }
      setTextareas(arr)
    }
  }, [])


  return (
    <Fragment>
      {multiLangTitles && inputHandler ? (
        inputs.map(input => (
          <Form.Input
            key={input}
            name={input}
            placeholder={placeholder + '.  ' + input.split('[')[1].split(']')[0]}
            value={multiLangTitles[input]}
            isValid={multiLangTitles[input].length >= minLength}
            inputHandler={inputHandler}
          />
        ))
      ) : multiLangDescriptions && textareaHandler ? (
        textareas.map(textarea => (
          <Form.Textarea
            key={textarea}
            name={textarea}
            placeholder={placeholder + '.  ' + textarea.split('[')[1].split(']')[0]}
            value={multiLangDescriptions[textarea]}
            isValid={multiLangDescriptions[textarea].length >= minLength}
            textareaHandler={textareaHandler}
          />
        ))
      ) : null}
    </Fragment>
  )
}

export default MultiLang
