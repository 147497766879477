import React, {
    useState,
    useEffect,
    useContext,
    Fragment,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'

import { useHttp } from '../../hooks'

import {
    config,
    Translater
} from '../../config'

import {
    ICategory, IFilter, IPagination, IRestaurant, TCategorySortBy, TSortBy,
} from '../../types/items'
import { SearchSelect } from "../../components/Page";


const IndexPage: React.FC = () => {

    const { token } = useContext(Contexts.AuthContext)
    const { access } = useContext(Contexts.UserContext)
    const { language } = useContext(Contexts.LanguageContext)

    const { loading, request, totalCount } = useHttp()

    const [primary, setPrimary] = useState<ICategory[]>([])
    const [pagination, setPagination] = useState<IPagination>({
        page: 1,
        perPage: 10,
        totalCount: 0,
    })
    const [searchValue, setSearchValue] = useState<string>("");
    const [defaultValue, setDefaultValue] = useState<ICategory[]>([]);
    const [restaurants, setRestaurants] = useState<IRestaurant[]>([])
    const [searchRest, setSearchRest] = useState<string>('')
    const [filter, setFilter] = useState<IFilter>({
        sortBy: 'name',
        sortOrder: 'asc',
    })

    const Events = {
        reloadHandler: () => {
            Callbacks.Fetch()
        },

        searchHandler: (e: any) => {
            if (e.target.value.length == 0) {
                setPrimary(defaultValue)
            }
            setSearchValue(e.target.value);
        },

        pressHandler: (e: any) => {
            if (e.key === 'Enter') Callbacks.Search()
        },
        sortHandler: (name: TSortBy | TCategorySortBy) => {
            let sortOrder: 'asc' | 'desc'
            if (filter.sortOrder === 'asc') {
                sortOrder = 'desc'
            } else {
                sortOrder = 'asc'
            }
            setFilter({
                sortBy: name,
                sortOrder
            })
        },

        paginateHandler: (page: number) => {
            setPagination({ ...pagination, page });
        },

        selectHandler: (e: any) => {
            const perPage = +e.target.value;
            setPagination({ ...pagination, perPage });
        },
        optionHandler: (option: IRestaurant) => {
            setSearchRest('')
            const _id = option._id
            setSearchRest(_id)
        },
        clearFilterHandler: () => {
            setSearchRest('');
        }
    }
    const findTitleRestById = () => {
        const elem = restaurants.find((item) => item._id === searchRest)
        if (elem) {
            const result = elem?.title
            return result
        }
        else {
            return searchRest[0]
        }
    }
    const Callbacks = {
        Fetch: async () => {
            try {

                function createUrl() {
                    const url = `${config.API}/categories/?children=1&restaurant[]=`

                    const string = searchRest && replaceAll(searchRest?.toString(), ',', '&restaurant[]=')

                    function replaceAll(string: string, search: string, replace: string): string {
                        return string.split(search).join(replace);
                    }
                    return url + string
                }
                if (searchRest.length === 0) {
                    const response: ICategory[] = await request(
                        `${config.API}/categories/?children=1&page=${pagination.page}&perPage=${pagination.perPage}&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}`,
                        'GET', null, {
                        Authorization: (token as string)
                    })
                    if (response) {
                        setDefaultValue(response);
                        setPrimary(response)
                    }
                } else {
                    const response: ICategory[] = await request(
                        createUrl() + `&page=${pagination.page}&perPage=${pagination.perPage}&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}`,
                        'GET', null, {
                        Authorization: (token as string)
                    })

                    if (response) {
                        setDefaultValue(response);
                        setPrimary(response)
                    }
                }

            } catch (e) {
                console.log(e)
            }
        },
        FetchRestaurants: async () => {
            try {
                const response: IRestaurant[] = await request(
                    `${config.API}/restaurants/`,
                    'GET',
                    null,
                    {
                        Authorization: token as string,
                    }
                )
                if (response) {
                    setRestaurants(response)
                }
            } catch (e) {
                console.log(e)
            }
        },
        Search: async () => {
            try {
                setPrimary([]);
                const search = await request(`${config.API}/categories/search?title=${searchValue}&page=${pagination.page}&perPage=${pagination.perPage}`, "GET", null, {
                    Authorization: token as string
                });


                if (search) {
                    setPrimary(search);
                }
            } catch (e) {
                console.log(e);
            }
        },
    }

    useEffect(() => {
        Callbacks.Fetch()
    }, [pagination.page, pagination.perPage, filter.sortBy, filter.sortOrder, searchRest]);

    useEffect(() => {
        Callbacks.FetchRestaurants()
    }, []);

    useEffect(() => {
        setPagination({ ...pagination, totalCount });
    }, [totalCount]);

    if (loading || !primary) return <Page.Preloader />

    return (
        <Page.Wrapper
            title={Translater.CategoriesPage.title[language.slug]}
        >
            <Page.Header
                title={Translater.CategoriesPage.title[language.slug]}
                enableButton={access.categories?.rule === 'change'}
                linkTitle={Translater.Buttons.create[language.slug]}
                linkTo='/categories/create'
            />

            <div className='filter-container'>

                <SearchSelect height={47} width={290} data={restaurants} placeholder={Translater.CategoriesPage.pickRestaurant[language.slug]} value={searchRest ? searchRest : null}
                    searchField='title' defaultValue={findTitleRestById()}
                    setSwitch={Events.optionHandler} />

                <button
                    className='btn btn--default btn--default--green clear-filter'
                    onClick={Events.clearFilterHandler}
                >
                    {Translater.Items.Clear[language.slug]}
                </button>



                <Page.SortBar
                    searchBar
                    filter={filter}
                    value={searchValue}
                    perPage={pagination.perPage}
                    pressHandler={Events.pressHandler}
                    inputHandler={Events.searchHandler}
                    selectHandler={Events.selectHandler}
                />



            </div>


            <Components.Table
                items={primary}
                reloadHandler={Events.reloadHandler}
                sortHandler={Events.sortHandler}
            />

            <Page.Footer>
                <Page.Pagination
                    pagination={pagination}
                    paginateHandler={Events.paginateHandler}
                    linkTo='/categories'
                    setPagination={setPagination}
                />
            </Page.Footer>

        </Page.Wrapper>
    )
}

export default IndexPage
