import React, { useState, useContext, useEffect } from 'react'

import '../../../scss/dragcard.scss'

import * as Contexts from '../../../contexts'
import { useHttp } from '../../../hooks/http.hook'

import { config, Translater } from '../../../config'

import { IRestaurant } from '../../../types/items'
import { DefaultButton } from '../../../components/Buttons'
import { TChangePositionProps } from './types'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'

const ChangePosition: React.FC<TChangePositionProps> = ({
  primary,

  setPrimary,
}) => {
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { request } = useHttp()

  const [currentCard, setCurrentCard] = useState<IRestaurant>()
  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)
  const [restaurantOrder, setRestaurantOrder] = useState<IRestaurant[]>(primary ? primary : []);

  const Events = {
    changePositionHandler: async () => {
      try {
        Callbacks.ChagePosition()
        toogleIsAlertOpen(true)
      } catch (error) {
        console.log(error)
      }
    },
    onDragEnd: (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      let items = Utils.reorder(
        restaurantOrder,
        result.source.index,
        result.destination.index
      );

      items = items.map((item, position) => ({ ...item, position: position + 1 }));

      setRestaurantOrder(
        [...items]
      );
    }
  }


  const Utils = {

    // a little function to help us with reordering the result
    reorder: (list: any[], startIndex: number, endIndex: number) => {
      const result = list;
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    }

  }

  const Callbacks = {
    ChagePosition: async () => {
      const restaurants: Array<Object> = []
      restaurantOrder?.forEach((item) => {
        const { _id, position, title } = item
        restaurants.push({ _id: _id, position: position })
      })
      try {
        const response = await request(
          `${config.API}/restaurants/updatePositions`,
          'PUT',
          {
            restaurants: restaurants,
          },
          {
            Authorization: token as string,
          }
        )
        console.log('res', response)
      } catch (e) {
        console.log(e)
      }
    },
  }

  const dragStartHandler = (e: DragEvent, item: IRestaurant) => {
    setCurrentCard(item)
  }
  const dragLeaveHandler = (e: DragEvent) => {
    e.preventDefault()
  }
  const dragEndHandler = (e: DragEvent) => { }
  const dragOverHandler = (e: DragEvent) => {
    e.preventDefault()
  }
  const dropHandler = (e: DragEvent, item: IRestaurant) => {
    e.preventDefault()
    setPrimary(
      //@ts-ignore
      primary.map((i) => {
        if (i._id == item._id) {
          return { ...i, position: currentCard?.position }
        }
        if (i._id == currentCard?._id) {
          return { ...i, position: item.position }
        }
        return i
      })
    )
  }

  const sortCard = (a: IRestaurant, b: IRestaurant) => {
    if (a.position! > b.position!) {
      return 1
    } else {
      return -1
    }
  }


  useEffect(() => {



    if (primary?.length) {
      let arr = primary;
      if (arr[0].position) {
        arr = arr.sort((a, b) => {


          if (a.position && b.position) {
            if (a.position > b.position) {

              return 1;
            }

            if (a.position < b.position) {

              return -1;
            }
          }



          return 0;
        });
      }


      setRestaurantOrder(arr);
    }
  }, [primary]);


  useEffect(() => {
    // Callbacks.ChagePosition();
  }, [restaurantOrder])

  return (
    <>
      <div>
        <p style={{ marginTop: 20 }}>
          {Translater.ChangePosition[language.slug]}
        </p>



        {/* <div className='dragContainer'>
          {primary.sort(sortCard).map((item) => (
            <div
              key={item._id}
              draggable={true}
              onDragStart={(e) => dragStartHandler(e as any, item)}
              onDragLeave={(e) => dragLeaveHandler(e as any)}
              onDragEnd={(e) => dragEndHandler(e as any)}
              onDragOver={(e) => dragOverHandler(e as any)}
              onDrop={(e) => dropHandler(e as any, item)}
              className='dragCard'
            >
              {
                <img
                  src={`${config.serverURL}${(item as IRestaurant).photo}`}
                  style={{ width: '100%', height: '100%', borderRadius: 9 }}
                />
              }
            </div>
          ))}
        </div> */}

        <DragDropContext onDragEnd={Events.onDragEnd}>
          <Droppable droppableId="droppable" >
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}

              >
                {restaurantOrder && restaurantOrder.map((restaurant, index) => (
                  <Draggable key={restaurant._id as string} draggableId={restaurant._id as string} index={index} >
                    {(provided, snapshot) => (
                      <div className={'dragCard'}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}

                      >
                        <img
                          src={`${config.serverURL}${(restaurant as IRestaurant).photo}`}
                          style={{ width: '100%', height: '100%', borderRadius: 9 }}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>


        <div
          style={{
            flex: 1,
            alignItems: 'center',
            marginTop: 20,
          }}
        >
          <DefaultButton
            buttonHandler={Events.changePositionHandler}
            title={Translater.SaveButton[language.slug]}
          />
        </div>
      </div>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity='success'>
          <p>{Translater.Success.save[language.slug]}</p>
        </Alert>
      </Snackbar>
    </>
  )
}

export default ChangePosition
