import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  ChangeEvent,
} from 'react'

import * as Page from '../../components/Page'
import * as Form from '../Form'
import * as Contexts from '../../contexts'

import { IBlockProps } from './types'
import { config, Translater } from '../../config'
import { useHttp } from '../../hooks'
import { Table } from '.'
import {
  TSortBy,
  IFilter,
  IFeedback,
  IPagination,
} from '../../types/items'


const Block: React.FC<IBlockProps> = ({
  link,
  userID,
  productID
}) => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { totalCount, loading, request } = useHttp()

  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 5,
    totalCount: 0
  })

  const [filter, setFilter] = useState<IFilter>({
    sortBy: 'updated',
    sortOrder: 'desc',
  })

  const [primary, setPrimary] = useState<IFeedback[]>([])

  const Events = {
    paginateHandler: (index: number) => {
      setPagination({ ...pagination, page: index })
    },
    sortHandler: (sortBy: TSortBy) => {
      let sortOrder: 'asc' | 'desc'
      if (filter.sortOrder === 'asc') sortOrder = 'desc'
      else sortOrder = 'asc'
      setFilter({ sortBy, sortOrder })
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setPagination({ ...pagination, page: 1, perPage: +e.target.value })
    },
    reloadHandler: () => {
      Callbacks.Fetch()
    }
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const URL = userID
          ? `${config.API}/products/feedback/?userID=${userID}&perPage=${pagination.perPage}&page=${pagination.page}&sortBy=${filter.sortBy}&sortOrder=${filter.sortOrder}`
          : productID
            ? `${config.API}/products/${productID}/feedback/?perPage=${pagination.perPage}&page=${pagination.page}&sortBy=${filter.sortBy}&sortOrder=${filter.sortOrder}`
            : `${config.API}/products/feedback/?perPage=${pagination.perPage}&page=${pagination.page}&sortBy=${filter.sortBy}&sortOrder=${filter.sortOrder}`

        let response: IFeedback[] = []

        if (URL) {
          response = await request(URL,
            'GET', null, {
            Authorization: (token as string)
          })
        }

        if (URL && response) {
          setPrimary(response)
        }
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [pagination.page, pagination.perPage, filter.sortOrder, filter.sortBy])

  useEffect(() => {
    setPagination({ ...pagination, totalCount })
  }, [totalCount])

  if (loading && (!userID && !productID)) return <Page.Preloader />

  if (access.products?.children?.feedback && access.products?.children?.feedback.rule !== 'false') {
    return (
      <Fragment>

        <Page.SortBar
          filter={filter}
          perPage={pagination.perPage}
          selectHandler={Events.selectHandler}
        />

        {primary.length ? (
          <Table
            items={primary}
            type={userID ? 'user' : productID ? 'product' : 'base'}
            sortHandler={Events.sortHandler}
            reloadHandler={Events.reloadHandler}
          />
        ) : (userID || productID) ? (
          <Form.LabelField
            fontSize='small'
            label={Translater.TableTitles.feedback[language.slug]}
            text={Translater.TableTitles.null[language.slug]}
          />
        ) : null}

        {/* <Page.Footer>
          <Page.Pagination
            linkTo={link}
            pagination={pagination}
            paginateHandler={Events.paginateHandler}
          />
        </Page.Footer> */}
      </Fragment>
    )
  } else return null
}

export default Block