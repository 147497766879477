import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
} from 'react'


import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'

import * as Components from './components'
import { useHttp } from '../../hooks/http.hook'

import { config, Translater } from '../../config'

import { IStock, IPagination } from '../../types/items'

const Stocks: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { loading, totalCount, request } = useHttp()

  const [stocks, setStocks] = useState<IStock[]>([])
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: 0
  })

  const Events = {

    pressHandler: (e: KeyboardEvent) => {
      if (e.key === 'Enter') Callbacks.Fetch()
    },

    reloadHandler: () => {
      Callbacks.Fetch()
    },

    paginateHandler: (page: number) => {
      setPagination({ ...pagination, page })
    },

    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setPagination({ ...pagination, page: 1, perPage: +e.target.value })
    },
  }


  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IStock[] = await request(`${config.API}/stocks/all`,
          'GET', null, {
          Authorization: (token as string)
        })
        console.log('valh', response)
        if (response) {
          setStocks(response)
        }

      } catch (e) {
        console.log(e)
      }

    }
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [])

  if (loading || !stocks) return <Page.Preloader />
  return (
    <Page.Wrapper
      footer
      title={Translater.StocksPage.title[language.slug]}
    >
      <Page.Header
        title={Translater.StocksPage.title[language.slug]}
        linkTitle={Translater.Buttons.create[language.slug]}
        enableButton={access.stocks?.rule === 'change'}
        linkTo='/stocks/create'
      />

      <Page.SortBar
        perPage={pagination.perPage}
        selectHandler={Events.selectHandler}
      />

      <Components.Table
        items={stocks}
        reloadHandler={Events.reloadHandler}
      />

      <Page.Footer>
        <Page.Pagination 
          pagination={pagination}
          setPagination={setPagination}
          paginateHandler={Events.paginateHandler}
          linkTo='/stocks'
        />
      </Page.Footer>
    </Page.Wrapper>
  )
}

export default Stocks
