import React, { useContext, useState } from 'react'

import * as Contexts from '../../contexts'
import * as Fonts from '../Fonts'

import { Translater } from '../../config'
import { Alert } from '@material-ui/lab'
import { Snackbar } from '@material-ui/core'

const Disconnected: React.FC = () => {
  const { language } = useContext(Contexts.LanguageContext)

  const [isVisible, toggleVisible] = useState<boolean>(true)

  const clickHandler = () => {
    toggleVisible(!isVisible)
  }

  if (!isVisible) return null

  return (
    <Snackbar
      open={isVisible}
      autoHideDuration={10000}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={() => toggleVisible(false)}
    >
      <Alert severity='error' onClick={clickHandler} style={{ marginTop: 10 }}>
        <Fonts.Title
          title={Translater.Erros.ws[language.slug]}
          fontSize='small'
        />
      </Alert>
    </Snackbar>
  )
}

export default Disconnected
