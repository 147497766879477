import React, {
  useState,
  useEffect,
  useContext
} from 'react'


import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Form from "../../components/Form";

import * as Components from './components'
import { useHttp } from '../../hooks'

import { config, Translater } from '../../config'
import { TNews } from "./types";
import { IFilter, IPagination, IRestaurant, TSortBy } from '../../types/items'

const News: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  const { access, user } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  console.log(user);

  const { loading, totalCount, request } = useHttp()

  const [news, setNews] = useState<TNews[]>([])
  const [restaurantOptions, setRestaurantOptions] = useState<IRestaurant[]>([]);
  const [options, setOptions] = useState<any[]>([]);
  const [restId, setRestId] = useState<any>(null);
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: 0,
  });
  const [filter, setFilter] = useState<IFilter>({
    sortBy: 'updated',
    sortOrder: 'desc',
  })
  const Events = {

    pressHandler: (e: KeyboardEvent) => {
      if (e.key === 'Enter') Callbacks.Fetch()
    },

    reloadHandler: () => {
      Callbacks.Fetch()
    },

    paginateHandler: (page: number) => {
      setPagination({ ...pagination, page })
    },

    selectHandler: (e: React.ChangeEvent<HTMLSelectElement>) => {
      const perPage = +e.target.value;
      setPagination({ ...pagination, perPage });
    },

    restIdHandler: (e: React.ChangeEvent<HTMLSelectElement>) => {
      for (let option of options) {
        if (option.title === e.target.value) {
          setRestId(option);
        };
      };
    },
    sortHandler: (name: TSortBy) => {
      console.log("WORK");
      let sortOrder: 'asc' | 'desc'
      if (filter.sortOrder === 'asc') sortOrder = 'desc'
      else sortOrder = 'asc'
      setFilter({
        sortBy: name,
        sortOrder
      })
    }

  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: TNews[] = await request(
          `${config.publicAPI}/news/byRestaurant/${restId._id}/?page=${pagination.page}&perPage=${pagination.perPage}&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}`,
          'GET');
        if (response) {
          console.log("RESPONSE", response);
          setNews(response)
        }
      } catch (e) {
        console.log(e)
      }
    },
    loadRestaurantOptions: async () => {
      try {
        const response: IRestaurant[] = await request(
          `${config.API}/restaurants`,
          'GET',
          null,
          {
            Authorization: token as string,
          }
        )

        if (restaurantOptions) {
          setRestaurantOptions(response);
          let Arr: any[] = [];
          for (let res of response) {
            Arr.push({
              _id: res._id,
              title: res.title,
            });
          };
          setRestId(Arr[0]);
          setOptions(Arr);
        };
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [pagination.page, pagination.perPage, restId, filter]);

  useEffect(() => {
    Callbacks.loadRestaurantOptions();
  }, []);

  useEffect(() => {
    setPagination({ ...pagination, totalCount });
  }, [totalCount]);

  if (loading || !news) return <Page.Preloader />

  return (
    <Page.Wrapper
      footer
      title={Translater.NewsPage.title[language.slug]}
    >
      <Page.Header
        title={Translater.NewsPage.title[language.slug]}
        linkTitle={Translater.Buttons.create[language.slug]}
        enableButton={access.stocks?.rule === 'change'}
        linkTo='/news/create'
      />
      {/* Pick Restaurant */}

      <div className="flex-news-container">
        {restId?.title && (
          <Form.DefaultSelect
            options={options}
            value={restId.title}
            name='restaurants'
            field="title"
            selectHandler={Events.restIdHandler}
          />
        )}
        {restaurantOptions && news.length > 0 ? (
          <Page.SortBar
            perPage={pagination.perPage}
            selectHandler={Events.selectHandler}
          />
        ) : null}
      </div>

      <Components.Table
        items={news}
        restaurants={restaurantOptions}
        reloadHandler={Events.reloadHandler}
        sortHandler={Events.sortHandler}
      />

      <Page.Footer>
        <Page.Pagination
          linkTo="/news"
          paginateHandler={Events.paginateHandler}
          pagination={pagination}
          setPagination={setPagination}
        />
      </Page.Footer>
    </Page.Wrapper>
  )
}

export default News
