import React, {
    useState,
    useEffect,
    useContext,
    ChangeEvent, KeyboardEvent,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'

import {Translater, config} from '../../config'
import {useHttp} from '../../hooks'
import {IRole} from '../../types/items'
import {IPagination} from '../../types/items'

const IndexPage: React.FC = () => {

    const {token} = useContext(Contexts.AuthContext)
    const {access} = useContext(Contexts.UserContext)
    const {language} = useContext(Contexts.LanguageContext)

    const {loading, request, totalCount} = useHttp()
    const [searchValue, setSearchValue] = useState<string>('')

    const [primary, setPrimary] = useState<IRole[]>([])
    const [pagination, setPagination] = useState<IPagination>({
        page: 1,
        perPage: 10,
        totalCount: 0
    })

    const Events = {
        reloadHandler: () => {
            Callbacks.Fetch()
        },

        paginateHandler: (page: number) => {
            setPagination({...pagination, page})
        },

        selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
            setPagination({...pagination, page: 1, perPage: +e.target.value})
        },
        searchHandler: (e: ChangeEvent<HTMLInputElement>) => {
            setSearchValue(e.target.value)
        },
        pressHandler: (e: KeyboardEvent) => {
            if (e.key === 'Enter') Callbacks.Fetch()
        },
    }

    const Callbacks = {
        Fetch: async () => {
            try {
                const response: IRole[] = await request(`${config.API}/roles/?page=${pagination.page}&perPage=${pagination.perPage}&title=${searchValue}`
                    ,
                    'GET', null, {
                        Authorization: (token as string)
                    })

                if (response) {
                    setPrimary(response)
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    useEffect(() => {
        Callbacks.Fetch()
    }, [pagination.perPage, pagination.page])

    useEffect(() => {
        setPagination({...pagination, totalCount})
    }, [totalCount])

    if (loading || !primary) return <Page.Preloader/>

    return (
        <Page.Wrapper
            title={Translater.RolesPage.title[language.slug]}
        >
            <Page.Header
                title={Translater.RolesPage.title[language.slug]}
                linkTitle={Translater.Buttons.create[language.slug]}
                enableButton={access.admin?.children?.roles?.rule === 'change'}
                linkTo='/roles/create'
            />

            <Page.SortBar
                searchBar
                value={searchValue}
                perPage={pagination.perPage}
                selectHandler={Events.selectHandler}
                inputHandler={Events.searchHandler}
                pressHandler={Events.pressHandler}
            />

            <Components.Table
                items={primary}
                reloadHandler={Events.reloadHandler}
            />

            <Page.Footer>
                <Page.Pagination
                    pagination={pagination}
                    setPagination={setPagination}
                    paginateHandler={Events.paginateHandler}
                    linkTo='/roles'
                />
            </Page.Footer>
        </Page.Wrapper>
    )
}

export default IndexPage
