export enum ELanguage {
  LANGUAGE_STORAGE = 'LANGUAGE_STORAGE'
}

type PossibleLanguages = 'ua' | 'ru'

export type TLanguages = {
  [language in PossibleLanguages]: ILanguage
}

export interface ILanguage {
  slug: PossibleLanguages
  label: string
}

export interface ILanguageContextActions {
  SET_LANGUAGE: (language: ILanguage) => void
  GET_LANGUAGE: () => void
  SYNC_LANGUAGE: () => void
}

export interface ILangugageContext {
  language: ILanguage
  actions: ILanguageContextActions
}

export type TElementaryContextProvider = {
  (
    props: {
      children: React.ReactElement
    }
  ): React.ReactElement
}

export interface ITranslater {
  [property: string]: {
    [property: string]: {
      ua: string
      ru: string
    }
  } | {
    ua: string
    ru: string
  }
}
