import React from 'react'

import * as Table from '../../../components/Table'

import { ITableWrapperProps } from './types'

import {
  SBaseTitles,
  SUserTitles,
  SProductTitles,
} from './static'
import Track from './Track'


const TableWrapper: React.FC<ITableWrapperProps> = ({
  type,
  items,
  sortHandler,
  reloadHandler
}) => {

  return (
    <Table.Table
      type={type !== 'base' ? 'feedback_brief' : 'feedback'}
      titles={
        type === 'user'
          ? SProductTitles
          : type === 'product'
            ? SUserTitles
            : SBaseTitles}
      sortHandler={sortHandler}
    >
      {items.map((item, index) => (
        <Track
          data={item}
          type={type}
          key={item._id}
          order={index + 1}
          reloadHandler={reloadHandler}
        />
      ))}
    </Table.Table>
  )
}

export default TableWrapper