import React from 'react'
import { IHeaderProps } from './types'
import { Link, useHistory } from 'react-router-dom'


const Header: React.FC<IHeaderProps> = ({
  title,
  enableButton = false,
  linkTo,
  linkTitle,
  backButtonTitle,
  btnTitle,
  buttonHandler,
  backButtonLink
}) => {

  const history = useHistory()

  return (
    <div className='page-header'>
      {title ? (
        <h2 className='page-title'>
          {title}
        </h2>
      ) : null}

      {backButtonTitle ? (
        <button
          className='btn btn--back'
          onClick={backButtonLink ? () => {
            history.push(backButtonLink)
          } : history.goBack}
        >
          <img
            alt='Back'
            src={require('../../assets/icons/arrow-left.png')}
          />
          {backButtonTitle}
        </button>
      ) : null
      }


      {
        enableButton ? (
          <Link
            className='btn btn--default btn--default--green'
            to={(linkTo as string)}
          >
            {linkTitle}
          </Link>
        ) : null
      }

      {
        btnTitle ? (
          <button
            className='btn btn--default btn--default--green'
            onClick={buttonHandler}
          >
            {btnTitle}
          </button>
        ) : null
      }
    </div >
  )
}

export default Header
