import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Buttons from '../../components/Buttons'
import * as Components from './components'

import { config, Translater } from '../../config'
import { useHttp } from '../../hooks/http.hook'
import { useHistory, useParams } from 'react-router-dom'
import { IForm, TDetailValidationSchema } from './types'
import { useValidation } from '../../hooks'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

const DetailPage: React.FC = () => {
  const { id } = useParams() as any
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const history = useHistory()
  const { loading, request } = useHttp()

  const [primary, setPrimary] = useState<any | null>(null)
  const [loader, toogleLoader] = useState<boolean>(false);

  const [form, setForm] = useState<IForm>({
    user: null,
    sum: "0",
  })

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const schema = useMemo<TDetailValidationSchema>(
    () => ({
      sum: {
        condition: +form.sum > 0 && +form.sum <= 100,
        error: `
          ${Translater.ErrorSize[language.slug]}:
          ${Translater.TableTitles.sum[language.slug]}`,
      },
    }),
    [form, Translater, language]
  )
  const { errors, validationSchema, validation } = useValidation(schema)

  const Events = {
    saveHandler: async () => {
      try {
        await validation()

        Callbacks.Save()
      } catch (e) {
        toogleIsAlertOpen(true)
        console.log('saveHandler => e', e)
      }
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },
  }

  const Callbacks = {
    Fetch: async () => {
      toogleLoader(true)
      try {
        const response: any = await request(
          `${config.API}/referals/${id}`,
          'GET',
          null,
          {
            Authorization: token as string,
          }
        )

        if (response) {
          setForm(response);
          setPrimary(response);
        }
      } catch (e) {
        console.log(e)
      } finally {
        toogleLoader(false);
      }
    },
    Save: async () => {
      try {
        toogleLoader(true);
        console.log('Referals', form);
        const data = form.user ? { sum: form.sum, user: typeof form.user == 'string' ? form.user : (form.user._id && form.user._id) } : { sum: form.sum };

        const response = await request(
          `${config.API}/referals/${id}`,
          'POST', data,
          {
            Authorization: token as string,
          }
        )

        if (response) {
          history.goBack()
        }

      } catch (e) {
        console.log(e)
      } finally {
        toogleLoader(false);
      }
    },
    Delete: async () => {
      try {
        await request(`${config.API}/referals/${id}`, 'DELETE', null, {
          Authorization: token as string,
        })

        history.push('/referals')
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [id])

  return (
    <Page.Wrapper title={Translater.ReferalsDetailPage.title[language.slug]}>
      <Page.WaitWrapper
        loader={loader}
        data={form.sum.length > 0}
        withoutNoLength
      >
        <Page.Header
          backButtonTitle={Translater.ReferalsDetailPage.title[language.slug]}
        />

        <Buttons.Container
          disabled={loading}
          saveHandler={Events.saveHandler}
          deleteHandler={Events.deleteHandler}
        />

        <Components.ConfigBlock
          form={form}
          isValid={validationSchema}
          setForm={setForm}
          isDetailPage
        />

        <Snackbar
          open={isAlertOpen}
          autoHideDuration={10000}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          onClose={() => toogleIsAlertOpen(false)}
        >
          <Alert severity='error'>
            {errors.map((error) => (
              <p key={`error-item-${error}`}>- {error}</p>
            ))}
          </Alert>
        </Snackbar>

      </Page.WaitWrapper>
    </Page.Wrapper>
  )
}

export default DetailPage
