import React, { useContext, useEffect } from 'react'

import * as Contexts from '../../contexts'
import * as Form from '../Form'

import { Translater } from '../../config'

import { ISortBarProps } from './types'

import { SOptions } from './static'

const SortBar: React.FC<ISortBarProps> = ({
  searchBar = false,
  name = '',
  value = '',
  perPage, 
  filter,
  selectHandler,
  pressHandler = () => {},
  inputHandler = () => {},
  sortByHandler,
  sortByOptions,
}) => {
  const { language } = useContext(Contexts.LanguageContext)

  const selectLocalStorageHandler = (e: any) => {
    selectHandler && selectHandler(e);
    localStorage.setItem("PAGINATION_STORAGE", JSON.stringify({ perPage: +e.target.value }));
  };

  return (
    <div className='sort-bar-wrapper'>
      <div className='sort-bar-container'>
        {searchBar ? (
          <Form.Input
            name={name}
            value={value} 
            placeholder={Translater.Placeholders.searchInput[language.slug]}
            inputHandler={inputHandler}
            pressHandler={pressHandler}
          />
        ) : null}
      </div>

      <div className='sort-bar-container'>
        <div className='flex margin--sides'>
          <p className='sort-bar-title'>
            {(filter?.sortBy != undefined && sortByHandler != undefined && sortByOptions != undefined) && (
              <Form.Select 
                name="sortBy" 
                options={sortByOptions}
                value={filter.sortBy}
                large={false}
                selectHandler={sortByHandler}
              />
            )}
          </p>
        </div> 

        {selectHandler ? (
          <Form.Select
            name='perPage'
            options={SOptions}
            large={false}
            value={perPage}
            selectHandler={selectLocalStorageHandler}
          />
        ) : null}
      </div>
    </div>
  )
}

export default SortBar
