import React, {
    Fragment,
    useState,
    useEffect,
    useContext, ChangeEvent,
} from 'react'

import * as Contexts from '../../../contexts'
import * as Buttons from '../../../components/Buttons'
import * as Form from '../../../components/Form'

import { config, Translater } from '../../../config'
import { useHttp } from '../../../hooks'

import { IStaff, IRole, ICategory, ICity, IRestaurant } from '../../../types/items'
import { IConfigBlockProps } from './types'

import AsyncSelect from 'react-select/async'
import * as Page from "../../../components/Page";

const ConfigBlock: React.FC<IConfigBlockProps> = ({
    data,
    form,
    isValid,
    isCreate = false,
    inputHandler,
    selectHandler,
}) => {

    const { token } = useContext(Contexts.AuthContext)
    const { access } = useContext(Contexts.UserContext)
    const { language } = useContext(Contexts.LanguageContext)

    const { request } = useHttp()

    const [inputValue, setInputValue] = useState<string>('')


    const Events = {
        inputHandler: (value: string) => {
            setInputValue(value)
        },
        textareaHandler: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
            const name = e.target.name
            const value = e.target.value

            // setForm({...form, [name]: value})
        },
    }

    const Styles = {
        control: () => ({
            display: 'flex',
            border: '1px solid #e6e6e6',
            borderRadius: 5,
            outline: 0
        }),
        input: () => ({
            fontSize: 16
        })
    }

    /* useEffect(() => {
         if (data) setInputValue((data.role as IRole)?.title)
     }, [(data?.role as IRole)?.title])
     useEffect(() => {
         setInputValue((data?.city as ICity)?.name)
     }, [(data?.city as ICity)?.name])*/


    return (
        <Fragment>
            <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.phone[language.slug]}
                text={access.restaurants?.rule !== 'change' ? form.title : ''}
            >
                {access.restaurants?.rule === 'change' ? (
                    <Form.Input
                        name='phone'
                        type={'number'}
                        value={form.phone}
                        isValid={isValid.phone}
                        //  placeholder={Translater.Placeholders.minThree[language.slug]}
                        inputHandler={inputHandler}
                    />
                ) : null}
            </Form.LabelField>

            <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.guests[language.slug]}
            >
                <Form.Input
                    name='guests'
                    type={'number'}
                    value={form.guests}
                    isValid={form.guests}
                    //  placeholder={Translater.Placeholders.minThree[language.slug]}
                    inputHandler={inputHandler}
                />
            </Form.LabelField>

            <Form.LabelField
                fontSize='small'
                label={Translater.TableTitles.date[language.slug]}
            // text={form.description}
            >
                {(
                    <Form.Input
                        name='date'
                        type={'date'}
                        // placeholder={Translater.Placeholders.minTwo[language.slug]}
                        // isValid={isValid.description}
                        value={form.date}
                        inputHandler={inputHandler}
                    />
                )}
            </Form.LabelField>
        </Fragment>
    )
}

export default ConfigBlock
