import React, { Fragment, useContext } from 'react'

import * as Contexts from '../../../../contexts'
import * as Buttons from '../../../../components/Buttons'
import * as Form from '../../../../components/Form'

import { config, Translater } from '../../../../config'
import { IDetailBlockProps } from './types'

import ListProducts from '../../ListProducts'

const DetailBlock: React.FC<IDetailBlockProps> = ({
  data
}) => {

  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)
  return (
    <Fragment>
      <Form.LabelField
        fontSize='small'
        label={access.stocks?.rule !== 'change' ? (
          Translater.TableTitles.photo[language.slug]
        ) : ''}
      >

      </Form.LabelField>



      <Form.DateBlock
        updated={(data.updated as string)}
        created={(data.created as string)}
      />


    </Fragment>
  )
}
export default DetailBlock